import { colors } from '@/styles/colors'
import { IconVariant } from '@/types/svgTypes'

export const ICON_COLORS: Record<IconVariant, string> = {
  brand: colors.brand,
  gray40: colors.gray40,
  gray30: colors.gray30,
  white: colors.white,
  green: colors.green,
}
