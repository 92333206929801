import { css } from 'styled-components'

export const transitions = {
  hover: css`
    transition: background-color 200ms ease-in-out, color 200ms ease-in-out,
      border-color 200ms ease-in-out;

    path {
      transition: fill 200ms ease-in-out;
    }
  `,
}
