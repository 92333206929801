import Box from '@/atoms/Box'
import Banner from '@/molecules/Banner'
import { ReactNode } from 'react'

type Props = {
  text?: string
  children?: ReactNode
}

const DEFAULT_TEXT = 'Excuses, er is iets misgegaan. Herlaad de pagina om het opnieuw te proberen.'

const InlineError = ({ children, text = DEFAULT_TEXT }: Props) => (
  <Box margin="md">
    <Banner content={text}>{children}</Banner>
  </Box>
)

export default InlineError
