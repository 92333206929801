import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
var _excluded = ["label", "loadingLabel", "loading", "link", "route", "type"];
var __jsx = React.createElement;
import * as React from 'react';
import styled, { css } from 'styled-components';
import { fonts, colorPalette } from './../../../styles/styleGuide';
import Link from '@/components/atoms/Link';
import { fromBreakpoint } from '../../../styles/breakpoints';
import { darken } from './../../../styles/utils';
import { connect } from 'react-redux';
import { getResultData } from '../../../redux/apiData';
import Spinner from '../ui/Spinner';
import get from 'lodash.get';
import withMatch from '@/hocs/withMatch';
var mapStateToProps = function mapStateToProps(state, ownProps) {
  return {
    styling: get(getResultData(state.apiData, 'getSettings', {
      projectSlug: String(ownProps.match.params.projectSlug),
      env: String(ownProps.match.params.env)
    }), 'styling', {})
  };
};
var defaultProps = {
  size: 'normal'
};
var ButtonPrimary = function ButtonPrimary(props) {
  var label = props.label,
    loadingLabel = props.loadingLabel,
    loading = props.loading,
    link = props.link,
    route = props.route,
    type = props.type,
    elementProps = _objectWithoutProperties(props, _excluded);
  var buttonColor = props.buttonColor ? props.buttonColor : props.match.params.env === 'closed' ? colorPalette.blue : props.styling && props.styling.button ? props.styling.button : colorPalette.blue;
  switch (type) {
    case 'button':
    case 'submit':
      return __jsx(Button, _extends({
        color: buttonColor,
        labelColor: props.labelColor,
        buttonHoverColor: props.buttonHoverColor,
        labelHoverColor: props.labelHoverColor,
        onClick: props.clickHandler,
        size: props.size
      }, elementProps, {
        "aria-label": "Knop",
        "data-id": "buttons_ButtonPrimary_Button"
      }), loading && __jsx(Loading, {
        "data-id": "buttons_ButtonPrimary_Loading"
      }, __jsx(Spinner, {
        monochrome: true,
        color: "#fff",
        size: "small",
        "data-id": "buttons_ButtonPrimary_Spinner"
      }), loadingLabel && __jsx("span", {
        "data-id": "buttons_ButtonPrimary_span"
      }, loadingLabel, "...")), props.children, !loading && __jsx("span", {
        "data-id": "buttons_ButtonPrimary_span_4"
      }, label));
    case 'externalLink':
      return __jsx(ExternalLink, _extends({
        href: link,
        color: buttonColor
      }, elementProps, {
        "data-id": "buttons_ButtonPrimary_ExternalLink"
      }), loading && __jsx(Loading, {
        "data-id": "buttons_ButtonPrimary_Loading"
      }, __jsx(Spinner, {
        monochrome: true,
        color: "#fff",
        size: "small",
        "data-id": "buttons_ButtonPrimary_Spinner"
      }), loadingLabel && __jsx("span", {
        "data-id": "buttons_ButtonPrimary_span"
      }, loadingLabel, "...")), props.children, !loading && __jsx("span", {
        "data-id": "buttons_ButtonPrimary_span_9"
      }, label));
    case 'internalLink':
    default:
      return __jsx(InternalLink, {
        href: route || link,
        label: label,
        color: buttonColor,
        id: props.id,
        onClick: props.clickHandler,
        "data-id": "buttons_ButtonPrimary_InternalLink"
      }, loading && __jsx(Loading, {
        "data-id": "buttons_ButtonPrimary_Loading"
      }, __jsx(Spinner, {
        monochrome: true,
        color: "#fff",
        size: "small",
        "data-id": "buttons_ButtonPrimary_Spinner"
      }), loadingLabel && __jsx("span", {
        "data-id": "buttons_ButtonPrimary_span"
      }, loadingLabel, "...")), props.children, !loading && __jsx("span", {
        "data-id": "buttons_ButtonPrimary_span_14"
      }, label));
  }
};
ButtonPrimary.defaultProps = defaultProps;
export default withMatch(connect(mapStateToProps)(ButtonPrimary));
var styling = css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: inline-block;\n  align-self: flex-start;\n  flex-shrink: 0;\n  align-items: center;\n  font-family: ", ";\n  transition: all 0.2s ease-in-out;\n  font-size: ", ";\n  font-weight: bold;\n  text-decoration: none;\n  color: ", ";\n  background-color: ", ";\n  min-width: ", ";\n  box-sizing: border-box;\n  padding: 7rem 15rem;\n  border-radius: 8rem 0 8rem 0;\n  text-align: center;\n  width: auto;\n\n  svg {\n    margin-right: 5rem;\n  }\n\n  &:hover {\n    background-color: ", ";\n\n    > span {\n      color: ", ";\n    }\n  }\n\n  ", ";\n"])), fonts.primary, function (props) {
  return props.size === 'normal' ? '18rem' : '16rem';
}, function (props) {
  return props.labelColor ? props.labelColor : '#ffffff';
}, function (props) {
  return props.color ? props.color : colorPalette.blue;
}, function (props) {
  return props.size === 'normal' ? '160rem' : '0';
}, function (props) {
  return props.buttonHoverColor ? props.buttonHoverColor : props.color ? darken(props.color, 15) : colorPalette.darkBlue;
}, function (props) {
  return props.labelHoverColor ? props.labelHoverColor : props.labelColor;
}, fromBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    font-size: ", ";\n  "])), function (props) {
  return props.size === 'normal' ? '20rem' : '18rem';
}));
var Loading = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n\n  > div {\n    margin-right: 10rem;\n  }\n\n  > span {\n    font-style: italic;\n  }\n"])));
var ExternalLink = styled.a(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", ";\n"])), styling);
var InternalLink = styled(Link)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", ";\n"])), styling);
var Button = styled.button(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  ", ";\n"])), styling);