import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
var __jsx = React.createElement;
import React from 'react';
import styled, { keyframes } from 'styled-components';
var defaultProps = {
  fullscreen: false,
  monochrome: false
};
var ContentLoader = function ContentLoader(_ref) {
  var _ref$big = _ref.big,
    big = _ref$big === void 0 ? false : _ref$big,
    _ref$fit = _ref.fit,
    fit = _ref$fit === void 0 ? false : _ref$fit;
  var path = 'M33.0063627,3.08548302 L62.4,20.7872274 L62.4,56.1 C62.4,59.1807119 59.9807119,61.6 56.8950787,61.5999952 L3.6067526,61.4950969 L3.49614885,20.6823142 L33.0063627,3.08548302 Z';
  return __jsx(Container, {
    big: big,
    fit: fit,
    "data-id": "ui_ContentLoader_Container"
  }, __jsx(Inner, {
    "data-id": "ui_ContentLoader_Inner"
  }, __jsx("svg", {
    width: "65px",
    height: "65px",
    viewBox: "0 0 65 65",
    "data-id": "ui_ContentLoader_svg"
  }, __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "ui_ContentLoader_g"
  }, __jsx("path", {
    d: path,
    stroke: "#eee",
    strokeWidth: "5",
    strokeLinecap: "round",
    id: "bam-loader-bg",
    "data-id": "ui_ContentLoader_path"
  }), __jsx("path", {
    d: path,
    stroke: "#ddd",
    strokeWidth: "5",
    strokeLinecap: "round",
    id: "bam-loader-1",
    "data-id": "ui_ContentLoader_path_5"
  }), __jsx("path", {
    d: path,
    stroke: "#ccc",
    strokeWidth: "5",
    strokeLinecap: "round",
    id: "bam-loader-2",
    "data-id": "ui_ContentLoader_path_6"
  })))));
};
ContentLoader.defaultProps = defaultProps;
export default ContentLoader;
var OFFSET = -208;
var ARRAY = 208;
var DURATION = 2;
var dash = keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  0% {\n    stroke-dashoffset: ", ";\n  }\n  25% {\n    stroke-dashoffset: ", ";\n  }\n  100% {\n    stroke-dashoffset: ", ";\n  }\n"])), OFFSET, OFFSET * 2, OFFSET * 2);
var dash2 = keyframes(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  0% {\n    stroke-dashoffset: ", ";\n  }\n  25% {\n    stroke-dashoffset: ", ";\n  }\n  50% {\n    stroke-dashoffset: ", ";\n  }\n  100% {\n    stroke-dashoffset: ", ";\n  }\n"])), OFFSET, OFFSET, OFFSET * 2, OFFSET * 2);
var Container = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-grow: 1;\n  height: ", ";\n  overflow: hidden;\n  width: 100%;\n  box-sizing: border-box;\n  margin-left: auto;\n  margin-right: auto;\n  position: relative;\n"])), function (_ref2) {
  var big = _ref2.big,
    fit = _ref2.fit;
  if (fit) return '100%';
  if (big) return '85vh';
  return '400rem';
});
var Inner = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  width: 210rem;\n  height: 200rem;\n\n  > svg {\n    position: absolute;\n    width: 210rem;\n    height: 200rem;\n\n    #bam-loader-1,\n    #bam-loader-2 {\n      stroke-dasharray: ", ";\n      stroke-dashoffset: ", ";\n    }\n\n    #bam-loader-1 {\n      animation: ", " ", "s ease-in-out infinite;\n    }\n\n    #bam-loader-2 {\n      animation: ", " ", "s ease-in-out infinite;\n    }\n  }\n"])), ARRAY, OFFSET, dash, DURATION, dash2, DURATION);