import _slicedToArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import { GoogleTagManager, sendGTMEvent } from "@next/third-parties/google";
import React, { useEffect } from "react";
import { useRouter } from 'next/router';
import { useSelector } from "react-redux";
var TagManager = function TagManager() {
  var _process$env$NEXT_PUB;
  var router = useRouter();

  // Get phase & slug from stored back-end data to send to GTM
  var _useSelector = useSelector(function (state) {
      var apiData = state.apiData;
      var entities = apiData.entities;
      if (entities !== null && entities !== void 0 && entities.settings && Object.keys(entities.settings).length > 0) {
        var settings = entities.settings;
        var _projectUrl = Object.keys(settings)[0];
        var _parts = _projectUrl.split('/');
        return [settings[_projectUrl].phase, _parts[0]];
      }
      return [];
    }),
    _useSelector2 = _slicedToArray(_useSelector, 2),
    currentPhase = _useSelector2[0],
    projectSlug = _useSelector2[1];

  // Resend GTM pageview event when phase, projectSlug or route changes
  useEffect(function () {
    if (currentPhase && projectSlug) {
      sendGTMEvent({
        event: 'pageview',
        phase: currentPhase,
        projectName: projectSlug,
        referrer: router.asPath
      });
    }
  }, [currentPhase, projectSlug, router.asPath]);
  return __jsx(GoogleTagManager, {
    gtmId: (_process$env$NEXT_PUB = process.env.NEXT_PUBLIC_GTM_KEY) !== null && _process$env$NEXT_PUB !== void 0 ? _process$env$NEXT_PUB : '',
    dataLayer: ["phase", "projectName"],
    "data-id": "components_TagManager_GoogleTagManager"
  });
};
export default TagManager;