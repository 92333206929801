import styledNormalize from 'styled-normalize'
import { createGlobalStyle } from 'styled-components'
import { BreakpointSize, breakpointSizesInEm } from '@/styles/breakpoints'
import { colors } from '@/styles/colors'
import { FONT_SIZE_BASE } from '@/styles/fonts'

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}

  @font-face {
    font-family: 'Foco';
    font-weight: 400;
    font-display: auto;
    src: url('/fonts/Foco_W_Rg.eot?#iefix') format('embedded-opentype'), url('/fonts/Foco_W_Rg.woff') format('woff');
  }

  @font-face {
    font-family: 'Foco';
    font-weight: 700;
    font-display: auto;
    src: url('/fonts/Foco_W_Bd.eot?#iefix') format('embedded-opentype'), url('/fonts/Foco_W_Bd.woff') format('woff');
  }

  @font-face {
    font-family: 'Foco';
    font-weight: 400;
    font-style: italic;
    font-display: auto;
    src: url('/fonts/Foco_W_It.eot?#iefix') format('embedded-opentype'), url('/fonts/Foco_W_It.woff') format('woff');
  }

  html {
    font-size: 1px; /* stylelint-disable-line */
    font-family: 'Foco', sans-serif;
    -webkit-font-smoothing: antialiased;
  }

  body {
    font-family: 'Foco', sans-serif;
    color: ${colors.black};
    line-height: 1.4;
    -webkit-font-smoothing: antialiased;
    font-size: 15rem;
    height: 100%;
  }

  button {
    font: inherit;
    border: none;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    outline: none;
  }

  h1, h2, h3, h4, h5, h6 {
    hyphens: auto;
  }
`

export const GlobalStyleHouseDesign = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    background-color: ${colors.gray10};
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    // increase base font size with viewport width
    font-size: 0.9px; /* stylelint-disable-line */

    @media (min-width: ${breakpointSizesInEm[BreakpointSize.xl]}em) {
      font-size: 0.95px; /* stylelint-disable-line */
    }

    @media (min-width: ${breakpointSizesInEm[BreakpointSize.xxl]}em) {
      font-size: 1px;  /* stylelint-disable-line */
    }
  }
  body {
    color: ${colors.gray40};
    line-height: 1.5;
    font-size: ${FONT_SIZE_BASE * 2}rem;
  }
`

export default GlobalStyle
