import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { colorPalette } from './../../../../styles/styleGuide';
import { fromBreakpoint } from '../../../../styles/breakpoints';
import Label from './Label';
import ErrorMessage from './ErrorMessage';
var defaultProps = {};
var Checkbox = function Checkbox(_ref) {
  var children = _ref.children,
    name = _ref.name,
    required = _ref.required,
    defaultChecked = _ref.defaultChecked,
    label = _ref.label,
    onChange = _ref.onChange,
    value = _ref.value,
    isValid = _ref.isValid,
    validationMessage = _ref.validationMessage;
  return __jsx(FormCheckboxContainer, {
    "data-id": "fieldTypes_Checkbox_FormCheckboxContainer"
  }, __jsx(FormCheckbox, {
    id: name,
    required: required,
    name: name,
    type: "checkbox",
    value: value,
    defaultChecked: Array.isArray(defaultChecked) ? defaultChecked.length : defaultChecked,
    onClick: onChange,
    "data-id": "fieldTypes_Checkbox_FormCheckbox"
  }), __jsx(Label, {
    name: name,
    required: required,
    "data-id": "fieldTypes_Checkbox_Label"
  }, children || label), validationMessage && __jsx(ErrorMessage, {
    isValid: isValid,
    message: validationMessage,
    "data-id": "fieldTypes_Checkbox_ErrorMessage"
  }));
};
Checkbox.defaultProps = defaultProps;
export default Checkbox;
export var FormCheckboxContainer = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: relative;\n  display: inline-flex;\n"])));
export var FormCheckbox = styled.input(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  opacity: 0;\n  width: 24rem;\n  height: 24rem;\n\n  + label {\n    color: ", ";\n    font-style: normal;\n    padding-left: 10rem;\n\n    ", ";\n\n    &:hover {\n      cursor: pointer;\n    }\n  }\n\n  + label {\n    &:before {\n      content: '';\n      width: 24rem;\n      height: 24rem;\n      box-sizing: border-box;\n      position: absolute;\n      left: 0;\n      top: 0;\n      background: #fff;\n      border: solid 1rem ", ";\n      border-radius: 4rem;\n    }\n  }\n  \n  &:disabled + label {\n    color: ", ";\n    cursor: not-allowed;\n    text-decoration: line-through;\n    \n    &:before {\n      background: ", ";\n      border: solid 1rem ", ";\n    }\n  }\n\n  &:checked + label:before {\n    background: url('/images/check-icon__blue.svg') no-repeat scroll center center transparent;\n  }\n"])), colorPalette.textGrey, fromBreakpoint.md(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n      padding-left: 15rem;\n    "]))), colorPalette.beige, colorPalette.lightBeige, colorPalette.background, colorPalette.lightBeige);