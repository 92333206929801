var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.orange,
  width: 15,
  height: 15
};
var PlaceholderUserCJ = function PlaceholderUserCJ(_ref) {
  var width = _ref.width,
    height = _ref.height,
    color = _ref.color;
  return __jsx("svg", {
    style: {
      width: "".concat(String(width), "px"),
      height: "".concat(String(height), "px")
    },
    viewBox: "0 0 78 78",
    version: "1.1",
    "data-id": "icons_PlaceholderUserCJ_svg"
  }, __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_PlaceholderUserCJ_g"
  }, __jsx("g", {
    transform: "translate(-681.000000, -656.000000)",
    fill: color,
    "data-id": "icons_PlaceholderUserCJ_g_2"
  }, __jsx("g", {
    transform: "translate(630.000000, 609.000000)",
    "data-id": "icons_PlaceholderUserCJ_g_3"
  }, __jsx("g", {
    transform: "translate(4.000000, 4.000000)",
    "data-id": "icons_PlaceholderUserCJ_g_4"
  }, __jsx("g", {
    transform: "translate(-1.000000, 0.000000)",
    "data-id": "icons_PlaceholderUserCJ_g_5"
  }, __jsx("path", {
    d: "M87.0009376,43 L126.001875,66.4005626 L125.879997,113.567321 C125.879997,117.589293 122.589293,120.879997 118.567321,120.879997 L48,120.879997 L48,66.2786846 L87.0009376,43 Z M87.0009376,50.0689199 L54.0938965,69.6912667 L54.2157744,114.664223 L116.129763,114.786101 C118.201688,114.786101 119.786101,113.201688 119.786101,111.129763 L119.786101,69.8131446 L87.0009376,50.0689199 Z M93.9650527,69.9698443 C99.2546853,69.9698443 103.540011,74.2572979 103.540011,79.5448027 C103.540011,84.8323075 99.2546853,89.1197612 93.9650527,89.1197612 C92.4905092,89.1197612 91.1095874,88.7580405 89.8584595,88.1622654 L81.9516716,96.0733088 L85.0135305,99.1372955 C85.845488,99.969253 85.845488,101.314003 85.0135305,102.14596 L84.2603004,102.89919 C83.8624077,103.297083 83.3219545,103.520499 82.755968,103.520499 C82.1921094,103.520499 81.6516562,103.297083 81.2516357,102.89919 L78.1940323,99.8352036 L74.8491802,103.177928 C74.4491597,103.577948 73.9087065,103.801364 73.3448478,103.801364 C72.7788614,103.801364 72.2384082,103.577948 71.8405154,103.177928 L70.3361831,101.673596 C69.5042256,100.843766 69.5042256,99.4968884 70.3361831,98.6649308 L85.3454624,83.6492682 C84.7518149,82.4023958 84.3900943,81.0193463 84.3900943,79.5448027 C84.3900943,74.2572979 88.6796757,69.9698443 93.9650527,69.9698443 Z M93.9650527,84.8642241 C96.8992456,84.8642241 99.2844741,82.4768677 99.2844741,79.5448027 C99.2844741,76.6127377 96.8992456,74.2253814 93.9650527,74.2253814 C91.03086,74.2253814 88.6456314,76.6127377 88.6456314,79.5448027 C88.6456314,82.4768677 91.03086,84.8642241 93.9650527,84.8642241 Z",
    "data-id": "icons_PlaceholderUserCJ_path"
  })))))));
};
PlaceholderUserCJ.defaultProps = defaultProps;
export default PlaceholderUserCJ;