import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18, _templateObject19, _templateObject20, _templateObject21, _templateObject22, _templateObject23, _templateObject24, _templateObject25, _templateObject26, _templateObject27, _templateObject28, _templateObject29, _templateObject30;
import { css, keyframes } from 'styled-components';
import { fromBreakpoint } from '../styles/breakpoints';
export var maxContentWidth = 1180;
export var gridGutter = 40;

// Colors
export var colorPalette = {
  blue: '#1FABDE',
  darkBlue: '#126685',
  lightBlue: '#56C9F3',
  loadingBlue: '#D5F2FC',
  orange: '#EC6608',
  darkOrange: '#993D00',
  lightOrange: '#FE9D32',
  loadingOrange: '#FEE7CC',
  signalOrange: '#D95600',
  yellow: '#EFD810',
  darkYellow: '##ffac00',
  lightYellow: '#f7ea81',
  loadingYellow: '#FEE7CC',
  green: '#0DB02B',
  darkGreen: '#076919',
  lightGreen: '#72C045',
  loadingGreen: '#DCEFD0',
  textGrey: '#544E48',
  headerGrey: '#696259',
  beige: '#8C8279',
  lightBeige: '#C6C1BC',
  lightGrey: '#E2DFDD',
  background: '#F5F3F2',
  red: '#D0021B',
  loadingRed: '#FFE6E9',
  white: '#FFFFFF'
};
export var colorPaletteRGB = {
  blue: '31,171,222',
  darkBlue: '18,102,133',
  lightBlue: '86,201,243',
  loadingBlue: '213,242,252',
  orange: '255,102,0',
  darkOrange: '153,61,0',
  lightOrange: '254,157,50',
  loadingOrange: '254,231,204',
  signalOrange: '217,86,0',
  green: '13,176,43',
  darkGreen: '7,105,25',
  lightGreen: '144,192,69',
  loadingGreen: '220,239,208',
  textGrey: '84,78,72',
  headerGrey: '105,98,89',
  beige: '140,130,121',
  lightBeige: '198,193,188',
  lightGrey: '226,223,221',
  background: '245,243,242',
  red: '208,2,27',
  loadingRed: '255,230,233',
  white: '255,255,255'
};

// Themes
export var themes = {
  algemeen: {
    primary: '#455c85',
    secondary: '#5d77a3',
    tertiary: '#d0d6e0',
    active: '#374762'
  },
  projectUpdate: {
    primary: colorPalette.orange,
    secondary: colorPalette.lightOrange,
    tertiary: colorPalette.loadingOrange,
    active: colorPalette.darkOrange
  },
  woonStyling: {
    primary: colorPalette.yellow,
    secondary: colorPalette.lightYellow,
    tertiary: colorPalette.loadingYellow,
    active: colorPalette.darkYellow
  },
  duurzaamheid: {
    primary: colorPalette.green,
    secondary: colorPalette.lightGreen,
    tertiary: colorPalette.loadingGreen,
    active: colorPalette.darkGreen
  },
  comfortabelWonen: {
    primary: '#7245B8',
    secondary: '#9C73DA',
    tertiary: '#E6DCF6',
    active: '#44296E'
  },
  testimonial: {
    primary: '#E65063',
    secondary: '#FF7485',
    tertiary: '#FFDCE0',
    active: '#8A303B'
  },
  begeleiding: {
    primary: '#34ABB5',
    secondary: '#74C4CB',
    tertiary: '#DDF1F2',
    active: '#1F666C'
  },
  woonklaar: {
    primary: '#9A0F31',
    secondary: '#B91A41',
    tertiary: '#EDC7D0',
    active: '#5C091D'
  }
};
export var ECWthemeMap = {
  bathroom: 'algemeen',
  doors: 'testimonial',
  extra: 'woonklaar',
  garden: 'begeleiding',
  kitchen: 'woonStyling',
  'floors-and-walls': 'comfortabelWonen',
  'living-plus': 'projectUpdate',
  lightning: 'projectUpdate',
  lighting: 'projectUpdate',
  'window-decoration': 'projectUpdate'
};
export var fonts = {
  primary: 'Foco, sans-serif'
};

// Shadows
export var shadows = {
  textPrimary: "text-shadow: 0 0 60rem rgba(0, 0, 0, .75)",
  boxPrimary: "box-shadow: 0rem 0rem 20rem 0rem rgba(0,0,0,0.15)",
  boxPrimaryHover: "box-shadow: 0rem 0rem 20rem 0rem rgba(0,0,0,0.25)"
};

// Animation constants
export var CUBIC_TIMING = 'cubic-bezier(0.04, 0.92, 0.26, 1.01)';

// TextStyles
export var textStyles = {
  pageTitle: css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    font-family: ", ";\n    font-weight: bold;\n    font-size: 32rem;\n    line-height: 38rem;\n\n    ", " ", ";\n  "])), fonts.primary, fromBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n      font-size: 40rem;\n      line-height: 48rem;\n    "]))), fromBreakpoint.lg(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n      font-size: 48rem;\n      line-height: 56rem;\n    "])))),
  sectionTitle: css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    font-family: ", ";\n    font-weight: bold;\n    font-size: 24rem;\n    line-height: 28rem;\n\n    ", " ", ";\n  "])), fonts.primary, fromBreakpoint.md(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n      font-size: 28rem;\n      line-height: 34rem;\n    "]))), fromBreakpoint.lg(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n      font-size: 32rem;\n      line-height: 38rem;\n    "])))),
  title: css(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n    font-family: ", ";\n    font-weight: bold;\n    font-size: 20rem;\n    line-height: 24rem;\n\n    ", ";\n  "])), fonts.primary, fromBreakpoint.md(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n      font-size: 22rem;\n      line-height: 30rem;\n    "])))),
  subTitle: css(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n    font-family: ", ";\n    font-weight: bold;\n    font-size: 16rem;\n    line-height: 24rem;\n\n    ", ";\n  "])), fonts.primary, fromBreakpoint.md(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n      font-size: 18rem;\n      line-height: 26rem;\n    "])))),
  introText: css(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n    font-family: ", ";\n    font-weight: normal;\n    font-size: 18rem;\n    line-height: 26rem;\n\n    ", ";\n  "])), fonts.primary, fromBreakpoint.md(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n      font-size: 22rem;\n      line-height: 32rem;\n    "])))),
  bodyText: css(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n    font-family: ", ";\n    font-weight: normal;\n    font-size: 16rem;\n    color: ", ";\n  "])), fonts.primary, colorPalette.textGrey),
  caption: css(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n    font-family: ", ";\n    font-weight: normal;\n    font-size: 14rem;\n    line-height: 20rem;\n  "])), fonts.primary),
  standardText: css(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n    font-family: ", ";\n    font-size: 16rem;\n    line-height: 24rem;\n  "])), fonts.primary),
  modalTitle: css(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n    font-family: ", ";\n    font-weight: bold;\n    font-size: 24rem;\n    line-height: 28rem;\n    color: ", ";\n    padding: 0;\n    margin: 0;\n    ", ";\n  "])), fonts.primary, colorPalette.headerGrey, fromBreakpoint.md(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n    font-size: 32rem;\n    line-height: 38rem;\n  "])))),
  formLabelDefault: css(_templateObject18 || (_templateObject18 = _taggedTemplateLiteral(["\n    box-sizing: border-box;\n    position: absolute;\n    left: 1rem;\n  "]))),
  formLabelAnimation: css(_templateObject19 || (_templateObject19 = _taggedTemplateLiteral(["\n    transition: transform 0.25s ", ";\n    transform: scale(0.75) translate(0, -26rem);\n    transform-origin: top left;\n  "])), CUBIC_TIMING)
};

// Animations
export var fadeIn = keyframes(_templateObject20 || (_templateObject20 = _taggedTemplateLiteral(["\n  from {\n    opacity: 0;\n  }\n\n  to {\n    opacity: 1;\n  }\n"])));
export var fadeInUp = keyframes(_templateObject21 || (_templateObject21 = _taggedTemplateLiteral(["\n  from {\n    opacity: 0;\n    transform: translate3d(0, 200px, 0);\n  }\n\n  to {\n    opacity: 1;\n    transform: none;\n  }\n"])));
export var fadeInDown = keyframes(_templateObject22 || (_templateObject22 = _taggedTemplateLiteral(["\n  from {\n    opacity: 0;\n    transform: translate3d(0, -200px, 0);\n  }\n\n  to {\n    opacity: 1;\n    transform: none;\n  }\n"])));
export var fadeInLeft = keyframes(_templateObject23 || (_templateObject23 = _taggedTemplateLiteral(["\n  from {\n    opacity: 0;\n    transform: translate3d(60px, 0, 0);\n  }\n\n  to {\n    opacity: 1;\n    transform: none;\n  }\n"])));
export var fadeInRight = keyframes(_templateObject24 || (_templateObject24 = _taggedTemplateLiteral(["\n  from {\n    opacity: 0;\n    transform: translate3d(-60px, 0, 0);\n  }\n\n  to {\n    opacity: 1;\n    transform: none;\n  }\n"])));
export var fadeOutDown = keyframes(_templateObject25 || (_templateObject25 = _taggedTemplateLiteral(["\n  0% {\n    opacity: 1;\n  }\n\n  75% {\n    opacity: 0;\n  }\n\n  100% {\n    display: none;\n  }\n"])));
export var rollOutLeft = keyframes(_templateObject26 || (_templateObject26 = _taggedTemplateLiteral(["\n  from {\n    opacity: 0;\n    transform: scaleX(0);\n  }\n\n  to {\n    opacity: 1;\n    transform: scaleX(1);\n  }\n"])));
export var rollOutTop = keyframes(_templateObject27 || (_templateObject27 = _taggedTemplateLiteral(["\n  from {\n    opacity: 0;\n    transform: scaleY(0);\n  }\n\n  to {\n    opacity: 1;\n    transform: scaleY(1);\n  }\n"])));
export var moveUpLeft = keyframes(_templateObject28 || (_templateObject28 = _taggedTemplateLiteral(["\n  from {\n    opacity: 0;\n    transform: translate3d(20px, 20px, 0);\n  }\n\n  to {\n    opacity: 1;\n    transform: translate3d(0, 0, 0);\n  }\n"])));
export var loadInAnimation = keyframes(_templateObject29 || (_templateObject29 = _taggedTemplateLiteral(["\n  0% {\n    opacity: 0;\n    transform: translate3d(0,-15px,0);\n  }\n  100% {\n    opacity: 1;\n    transform: translate3d(0,0,0);\n  }\n"])));
export var loadOutAnimation = keyframes(_templateObject30 || (_templateObject30 = _taggedTemplateLiteral(["\n  0% {\n    opacity: 1;\n    transform: translate3d(0,0,0);\n  }\n  100% {\n    opacity: 0;\n    transform: translate3d(0,-15px,0);\n  }\n"])));