import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/**

  PLEASE SEE README.md FOR MORE INFORMATION ON THE REDUX SETUP AND LIBRARIES USED IN THIS APPLICATION.

*/
// state def
var defaultState = {
  showError: false
};

// actions

// reducer

export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case 'SHOW_API_ERROR':
      return _objectSpread(_objectSpread({}, state), {}, {
        showError: true
      }, action.payload);
    case 'HIDE_API_ERROR':
      return {
        showError: false
      };
    default:
      return state;
  }
});

// action creators

export var showApiError = function showApiError(endpointKey, response, body) {
  return {
    type: 'SHOW_API_ERROR',
    payload: {
      endpointKey: endpointKey,
      response: response,
      body: body
    }
  };
};
export var hideApiError = function hideApiError() {
  return {
    type: 'HIDE_API_ERROR'
  };
};