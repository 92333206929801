import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { fonts, colorPalette } from '../../../../styles/styleGuide';
var defaultProps = {};
var ErrorMessage = function ErrorMessage(_ref) {
  var isValid = _ref.isValid,
    color = _ref.color,
    message = _ref.message;
  return __jsx(Error, {
    isValid: isValid,
    color: color,
    "data-id": "fieldTypes_ErrorMessage_Error"
  }, message);
};
ErrorMessage.defaultProps = defaultProps;
export default ErrorMessage;
var Error = styled.span(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: ", ";\n  position: absolute;\n  box-sizing: border-box;\n  width: 100%;\n  background: ", ";\n  padding: ", ";\n  bottom: ", ";\n  font-family: ", ";\n  color: ", ";\n  left: 0;\n  font-size: 12rem;\n"])), function (props) {
  return props.isValid ? 'none' : 'block';
}, function (props) {
  return props.color ? '#ffffff' : 'none';
}, function (props) {
  return props.color ? '5rem 7rem' : '0';
}, function (props) {
  return props.color ? '-30rem' : '-22rem';
}, fonts.primary, colorPalette.red);