import { Match, MatchParams } from '@/types/match'
import { parseRouteParams } from '@/processes/parseRouteParams'
import { useRouter } from 'next/router'
import { PageType } from '@/types/pageType'
import { useMemo } from 'react'

export type UseMatchData = {
  loading: boolean
  error: boolean
  match: Match
  pageType: PageType
}

const useParams = (): MatchParams => {
  const {
    query: { slugs },
  } = useRouter()

  const { params } = useMemo(() => parseRouteParams(slugs), [slugs])

  return params
}

export default useParams
