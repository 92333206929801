var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from '../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.blue,
  width: 20,
  height: 20
};
var Upload = function Upload(_ref) {
  var width = _ref.width,
    height = _ref.height,
    color = _ref.color;
  return __jsx("svg", {
    style: {
      width: "".concat(String(width), "px"),
      height: "".concat(String(height), "px")
    },
    viewBox: "0 0 20 20",
    "data-id": "icons_Upload_svg"
  }, __jsx("title", {
    "data-id": "icons_Upload_title"
  }, "Upload"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_Upload_g"
  }, __jsx("g", {
    fill: color,
    fillRule: "nonzero",
    "data-id": "icons_Upload_g_3"
  }, __jsx("path", {
    d: "M9.93849519,0.0107950466 C10.3711561,-0.0424367909 10.8219367,0.1010185 11.1591383,0.445220975 L15.7639502,5.14563735 C16.3322873,5.72577414 16.341789,6.65530129 15.788036,7.25167831 L15.7891409,7.25280609 L14.7136757,8.35059996 L11.4329212,5.0035354 L11.4236404,13.5779213 L8.39214103,13.5776958 L8.4113655,5.14022393 L6.27148359,7.32453375 C5.68127041,7.92700086 4.751425,7.92519637 4.17645878,7.33829282 C4.174912,7.33671389 4.17402813,7.33490944 4.17270228,7.33355607 L3.13634891,6.27568608 L8.83054725,0.463265665 C9.14012744,0.14725802 9.54251538,-0.0027384715 9.93849519,0.0107950466 Z M3.02758235,16.8481736 L16.9724177,16.8481736 L16.9724177,14.2582146 C16.9724177,13.2011811 17.8118838,12.3442861 18.8474177,12.3442861 L20,12.3442861 L20,18.0860715 C20,19.143105 19.1605339,20 18.125,20 L1.875,20 C0.839466094,20 4.04372084e-16,19.143105 2.77555756e-16,18.0860715 L0,12.4787001 L1.15258235,12.4787001 C2.18811625,12.4787001 3.02758235,13.335595 3.02758235,14.3926285 L3.02758235,16.8481736 Z",
    "data-id": "icons_Upload_path"
  }))));
};
Upload.defaultProps = defaultProps;
export default Upload;