/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */
import React, {
  Children,
  ReactNode,
  cloneElement,
  Component,
  JSXElementConstructor,
  ReactElement,
  ReactPortal,
} from 'react'
import { Parser, Tag } from 'bbcode-to-react'
import type { BBCodeModel } from '@/entities/types'
import Title from '@/atoms/Title'
import Text from '@/atoms/Text'
import ImageText from '@/components/shared/richText/ImageText'
import ImageCentered from '@/components/shared/richText/ImageCentered'
import ListChecked from '@/components/shared/richText/ListChecked'
import ListUnOrdered from '@/components/shared/richText/ListUnOrdered'
import ListOrdered from '@/components/shared/richText/ListOrdered'
import ListItem from '@/components/shared/richText/ListItem'
import PageLinkInternal from '@/components/shared/richText/PageLinkInternal'
import PageLinkExternal from '@/components/shared/richText/PageLinkExternal'
import Quote from '@/components/shared/richText/Quote'
import ButtonDownload from '@/components/shared/buttons/ButtonDownload'
import ButtonSecondary from '@/components/shared/buttons/ButtonSecondary'
import ButtonPrimary from '@/components/shared/buttons/ButtonPrimary'
import Video from '@/components/shared/richText/Video'
import { useDispatch, useSelector } from 'react-redux'
import { clearModalState, updateModalOpenState } from '@/redux/modal'
import { performApiRequest } from '@/redux/apiData'
// eslint-disable-next-line import/no-cycle
import CollapsableForm from '@/molecules/CollapsableForm'

type BBCodeProps = {
  content: BBCodeModel
}

type UnknownProps = Record<string, unknown>

// Default HTML tags
class BrTag2 extends Tag {
  toReact() {
    return <br />
  }
}

class BoldTag2 extends Tag {
  toReact() {
    return <strong>{this.getComponents()}</strong>
  }
}

// Custom tags
class QuoteTag2 extends Tag {
  toReact() {
    return <Quote>{this.getComponents()}</Quote>
  }
}

class ListItemTag2 extends Tag {
  toReact() {
    // @ts-ignore
    return <ListItem type={this.parent.name}>{this.getComponents()}</ListItem>
  }
}

class ParagraphTag2 extends Tag {
  toReact() {
    return <Text withMargin>{this.getComponents()}</Text>
  }
}

const TypedButtonDownload = (props: {
  fileId?: number
  link?: string
  content: string
  image?: boolean
  elType: 'ButtonDownload'
  // @ts-ignore
}) => <ButtonDownload {...props} />

class DownloadButtonTag2 extends Tag {
  toReact() {
    return (
      <TypedButtonDownload
        // @ts-ignore
        fileId={this.params.file}
        content={this.getContent()}
        elType="ButtonDownload"
      />
    )
  }
}

class OrderedListTag2 extends Tag {
  toReact() {
    return <ListOrdered>{this.getComponents()}</ListOrdered>
  }
}

class UnOrderedListTag2 extends Tag {
  toReact() {
    return <ListUnOrdered>{this.getComponents()}</ListUnOrdered>
  }
}

class CheckListTag2 extends Tag {
  toReact() {
    return <ListChecked>{this.getComponents()}</ListChecked>
  }
}

const TypedButtonSecondary = (props: {
  id?: string
  label: string
  type?: 'button' | 'submit' | 'externalLink' | 'internalLink'
  link?: string
  route?: string
  color?: string
  colorHover?: string
  themeColor?: string
  caretColor?: string
  size?: 'small' | 'normal'
  clickHandler?: () => void
  // @ts-ignore
}) => <ButtonSecondary {...props} />

class SecondaryButtonTag2 extends Tag {
  toReact() {
    // @ts-ignore
    if (!this.params?.href) {
      return null
    }
    return (
      <TypedButtonSecondary
        type="externalLink"
        // @ts-ignore
        link={this.params.href}
        // @ts-ignore
        target={this.params.target}
        // @ts-ignore
        label={this.getContent()}
      />
    )
  }
}

class PrimaryButtonTag2 extends Tag {
  toReact() {
    // @ts-ignore
    if (!this.params?.href) {
      return null
    }
    return (
      <ButtonPrimary
        // @ts-ignore
        href={this.params.href}
        // @ts-ignore
        target={this.params.target}
        // @ts-ignore
        content={this.getContent()}
      />
    )
  }
}

class ImageTextTag2 extends Tag {
  toReact() {
    return (
      <ImageText
        elType="ImageText"
        // @ts-ignore
        mediaId={this.params.media}
        // @ts-ignore
        alignment={this.params.alignment}
        // @ts-ignore
        imageAlt={this.params.alt}
        // @ts-ignore
        title={this.params.title}
        components={this.getComponents()}
      />
    )
  }
}

class ImageCenteredTag2 extends Tag {
  toReact() {
    return (
      <ImageCentered
        elType="ImageCentered"
        // @ts-ignore
        mediaId={this.params.media}
        // @ts-ignore
        alt={this.params.alt}
        caption={this.getContent()}
      />
    )
  }
}

class InternalLinkTag2 extends Tag {
  toReact() {
    return (
      <PageLinkInternal
        // @ts-ignore
        pageId={this.params.pagenode}
        // @ts-ignore
        elType="PageLinkInternal"
        content={this.getContent()}
        // @ts-ignore
        pages={{}}
        // @ts-ignore
        type={this.params.type === 'skip' ? 'skip' : this.params.type}
      />
    )
  }
}

const TypedPageLinkExternal = (props: {
  content: string
  link: string
  type: 'link' | 'primaryButton' | 'secondaryButton'
  // @ts-ignore
}) => <PageLinkExternal {...props} />

class ExternalLinkTag2 extends Tag {
  toReact() {
    // @ts-ignore
    if (!this.params?.href) {
      return null
    }
    return (
      <TypedPageLinkExternal
        // @ts-ignore
        link={this.params.href}
        // @ts-ignore
        type={this.params.type}
        content={this.getContent()}
      />
    )
  }
}

class Heading2Tag2 extends Tag {
  toReact() {
    return (
      <Title
        level={2}
        size="lg"
        withMargin
      >
        {this.getComponents()}
      </Title>
    )
  }
}

class Heading3Tag2 extends Tag {
  toReact() {
    return (
      <Title
        level={3}
        size="md"
        withMargin
      >
        {this.getComponents()}
      </Title>
    )
  }
}

class CollapsableForm2 extends Tag {
  toReact() {
    // @ts-ignore
    if (!this.params && !this.params.form) {
      return null
    }

    return (
      <CollapsableForm
        buttonText={(this.getComponents()[0] as string) ?? 'Open formulier'}
        // @ts-ignore
        formId={this.params.form as string}
      />
    )
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CloseModalComponent = ({ content, params }: any) => {
  const dispatch = useDispatch()
  // Get modal config data from the redux state
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { modalConfig } = useSelector((state: any) => state.modal)
  const { componentProps } = modalConfig
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const apiData = useSelector((state: any) => state.apiData)
  const { entities } = apiData

  let settings
  let projectSlug: string
  let env: string

  if (entities) {
    settings = entities.settings
    if (settings) {
      const projectUrl = Object.keys(settings)[0]
      const parts = projectUrl.split('/')

      /**
       * Reassign values that are desctructured
       * It is not possible to directly assign the descructured values
       * to the let values above
       */
      const [splittedProjectSlug, splittedEnv] = parts
      projectSlug = splittedProjectSlug
      env = splittedEnv
    }
  }

  const closeModal = (evt: React.MouseEvent<HTMLButtonElement>) => {
    // @ts-ignore
    if ((evt && evt.type === 'click') || (evt && evt.type === 'keydown' && evt.keyCode === 27)) {
      dispatch(updateModalOpenState(false))
      dispatch(clearModalState())

      // Only fire postModalDismiss if all values are present
      if (componentProps && componentProps.id !== undefined && projectSlug && env) {
        // Destructure because otherwise we get an error
        const { id } = componentProps

        dispatch(
          // @ts-ignore
          performApiRequest('postModalDismiss', {
            projectSlug,
            env,
            modalId: id,
          })
        )
      }
    }
  }

  let renderComponent

  switch (params?.type) {
    case 'primaryButton':
      renderComponent = (
        <ButtonPrimary
          // @ts-ignore
          label={content}
          // @ts-ignore
          type="submit"
          // @ts-ignore
          clickHandler={closeModal}
        />
      )
      break
    case 'secondaryButton':
      renderComponent = (
        <TypedButtonSecondary
          type="submit"
          // @ts-ignore
          label={content}
          // @ts-ignore
          clickHandler={closeModal}
        />
      )
      break
    case 'link':
      renderComponent = (
        <ButtonPrimary
          // @ts-ignore
          label={content}
          // @ts-ignore
          type="submit"
          // @ts-ignore
          clickHandler={closeModal}
        />
      )
      break
    default:
      renderComponent = (
        <ButtonPrimary
          // @ts-ignore
          label={content}
          // @ts-ignore
          type="submit"
          // @ts-ignore
          clickHandler={closeModal}
        />
      )
      break
  }

  return renderComponent
}

/**
 * Usage in BB Code example:
 * [action="close" type="primaryButton"]Aan de slag[/action]
 * [action="close" type="link"]Sluiten[/action]
 * [action="close" type="secondaryButton"]Sluiten[/action]
 */
class CloseModal2 extends Tag {
  toReact() {
    if (!this.getContent() || !this.params) {
      return null
    }

    return (
      <CloseModalComponent
        content={this.getContent()}
        params={this.params}
      />
    )
  }
}

class Heading4Tag2 extends Tag {
  toReact() {
    return (
      <Title
        level={4}
        size="sm"
        withMargin
      >
        {this.getComponents()}
      </Title>
    )
  }
}

class YouTubeTag2 extends Tag {
  toReact() {
    if (!this.getComponents()) {
      return null
    }
    return (
      <Video
        type="youtube"
        id={this.getComponents()[0] as string}
      />
    )
  }
}

class VimeoTag2 extends Tag {
  toReact() {
    if (!this.getComponents()) {
      return null
    }
    return (
      <Video
        type="vimeo"
        id={this.getComponents()[0] as string}
      />
    )
  }
}

const p = new Parser()

const register = (name: string, tag: unknown) => p.registerTag(name, tag as typeof Tag)

// Please note: when using registerTag, the first argument must be all lowercase,
// otherwise the tag won't be recognized by the parser.
// Also see: https://github.com/JimLiu/bbcode-to-react/issues/11
register('quote', QuoteTag2)
register('li', ListItemTag2)
register('br', BrTag2)
register('b', BoldTag2)
register('strong', BoldTag2)
register('p', ParagraphTag2)
register('centeredimage', ImageCenteredTag2)
register('imagetext', ImageTextTag2)
register('olist', CheckListTag2)
register('ol', OrderedListTag2)
register('ul', UnOrderedListTag2)
register('h2', Heading2Tag2)
register('h3', Heading3Tag2)
register('h4', Heading4Tag2)
register('pagenode', InternalLinkTag2)
register('href', ExternalLinkTag2)
register('file', DownloadButtonTag2)
register('secundairebutton', SecondaryButtonTag2)
register('primarybutton', PrimaryButtonTag2)
register('youtube', YouTubeTag2)
register('vimeo', VimeoTag2)
register('action', CloseModal2)
register('form', CollapsableForm2)

class BBCodeHandler extends Component<BBCodeProps> {
  applyPropsToEls(props: BBCodeProps, element?: ReactNode) {
    return Children.map(element, (childElement): ReactNode => {
      // @ts-ignore
      if (!childElement || !childElement.type) {
        return childElement
      }

      const child = childElement as
        | ReactElement<UnknownProps, string | JSXElementConstructor<UnknownProps>>
        | ReactPortal

      const clone = (newProps?: UnknownProps) =>
        cloneElement(
          child,
          newProps,
          child.props.children ? this.applyPropsToEls(props, child.props.children) : null
        )

      switch (child.props.elType) {
        case 'ImageCentered':
        case 'ImageText':
          return clone({
            media: props.content.media,
          })

        case 'ButtonDownload':
          return clone({
            files: props.content.files,
          })

        case 'PageLinkInternal':
          return clone({
            pages: props.content.pages,
          })

        default:
          return clone()
      }
    })
  }

  render() {
    const { content } = this.props
    if (!content?.content) return null
    const elements = this.applyPropsToEls(this.props, p.toReact(content.content))

    return <div>{elements}</div>
  }
}

export default BBCodeHandler
