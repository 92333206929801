import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject;
var __jsx = React.createElement;
import React from 'react';
import { connect } from 'react-redux';
import withMatch from '@/hocs/withMatch';
import styled from 'styled-components';
import { getResultData } from '../../../redux/apiData';
import { colorPalette } from '../../../styles/styleGuide';
import { LinkStyles } from './PageLinkInternal';
import ButtonSecondary from '../buttons/ButtonSecondary';
import ButtonPrimary from '../buttons/ButtonPrimary';
import get from 'lodash.get';
var mapStateToProps = function mapStateToProps(state, ownProps) {
  return {
    styling: get(getResultData(state.apiData, 'getSettings', {
      projectSlug: String(ownProps.match.params.projectSlug),
      env: String(ownProps.match.params.env)
    }), 'styling', {})
  };
};
var PageLinkExternal = function PageLinkExternal(_ref) {
  var match = _ref.match,
    link = _ref.link,
    type = _ref.type,
    content = _ref.content,
    styling = _ref.styling;
  var _ref2 = match.params.env === 'closed' ? {
      button: colorPalette.blue
    } : styling,
    color = _ref2.button;
  switch (type) {
    case 'link':
      return __jsx(StyledLink, {
        href: link,
        color: color,
        target: link.startsWith('http') ? '_blank' : '',
        "data-id": "richText_PageLinkExternal_StyledLink"
      }, content);
    case 'primaryButton':
      return __jsx(ButtonPrimary, {
        label: content,
        type: "externalLink",
        link: link,
        target: link.startsWith('http') ? '_blank' : '',
        "data-id": "richText_PageLinkExternal_ButtonPrimary"
      });
    case 'secondaryButton':
    default:
      return __jsx(ButtonSecondary, {
        label: content,
        type: "externalLink",
        link: link,
        target: link.startsWith('http') ? '_blank' : '',
        "data-id": "richText_PageLinkExternal_ButtonSecondary"
      });
  }
};
export default withMatch(connect(mapStateToProps)(PageLinkExternal));
var StyledLink = styled.a(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", ";\n"])), LinkStyles);