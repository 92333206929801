import { ApiDataRequest } from '@/redux/apiData'

const onNetworkStatusChange = (
  callbacks: {
    onSucces?: () => void
    onFailed?: () => void
    always?: () => void
  },
  previousRequest?: ApiDataRequest,
  currentRequest?: ApiDataRequest
) => {
  const { onSucces, onFailed, always } = callbacks
  if (previousRequest?.networkStatus === 'loading' && currentRequest?.networkStatus === 'success') {
    onSucces?.()
    always?.()
    return
  }
  if (previousRequest?.networkStatus === 'loading' && currentRequest?.networkStatus === 'failed') {
    onFailed?.()
    always?.()
  }
}

export default onNetworkStatusChange
