import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var _excluded = ["colorHover"];
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
var __jsx = React.createElement;
import React from 'react';
import styled, { css } from 'styled-components';
import withMatch from '@/hocs/withMatch';
import { fonts, colorPalette } from './../../../styles/styleGuide';
import Link from '@/components/atoms/Link';
import { darken } from './../../../styles/utils';
import { connect } from 'react-redux';
import CaretContainer from '../designElements/CaretContainer';
import { getResultData } from '../../../redux/apiData';
import get from 'lodash.get';
var defaultProps = {
  type: 'internalLink',
  caretColor: 'white'
};
var mapStateToProps = function mapStateToProps(state, ownProps) {
  return {
    styling: get(getResultData(state.apiData, 'getSettings', {
      projectSlug: String(ownProps.match.params.projectSlug),
      env: String(ownProps.match.params.env)
    }), 'styling', {})
  };
};
var ButtonSecondary = function ButtonSecondary(props) {
  var _props$styling$button = props.styling.button,
    themeColor = _props$styling$button === void 0 ? colorPalette.blue : _props$styling$button;
  var color = props.color ? props.color : props.match.params.env === 'closed' ? colorPalette.blue : themeColor;
  switch (props.type) {
    case 'internalLink':
      return __jsx(PageRouteLink, {
        color: color,
        colorHover: props.colorHover,
        href: props.route,
        size: props.size,
        "data-id": "buttons_ButtonSecondary_PageRouteLink"
      }, __jsx(React.Fragment, null, __jsx("span", {
        "data-id": "buttons_ButtonSecondary_span"
      }, props.label), __jsx(CaretContainer, {
        color: color,
        caretColor: props.caretColor,
        size: props.size,
        "data-id": "buttons_ButtonSecondary_CaretContainer"
      })));
    case 'button':
    case 'submit':
      return __jsx(ButtonLink, _extends({}, props, {
        color: color,
        colorHover: props.colorHover,
        onClick: props.clickHandler,
        size: props.size,
        "aria-label": "Knop",
        "data-id": "buttons_ButtonSecondary_ButtonLink"
      }), __jsx(React.Fragment, null, __jsx("span", {
        "data-id": "buttons_ButtonSecondary_span"
      }, props.label), __jsx(CaretContainer, {
        color: color,
        caretColor: props.caretColor,
        size: props.size,
        "data-id": "buttons_ButtonSecondary_CaretContainer"
      })));
    case 'externalLink':
    default:
      return __jsx(NormalLink, _extends({}, props, {
        href: props.link,
        color: color,
        colorHover: props.colorHover,
        size: props.size,
        "data-id": "buttons_ButtonSecondary_NormalLink"
      }), __jsx(React.Fragment, null, __jsx("span", {
        "data-id": "buttons_ButtonSecondary_span"
      }, props.label), __jsx(CaretContainer, {
        color: color,
        caretColor: props.caretColor,
        size: props.size,
        "data-id": "buttons_ButtonSecondary_CaretContainer"
      })));
  }
};
ButtonSecondary.defaultProps = defaultProps;
export default withMatch(connect(mapStateToProps)(ButtonSecondary));
var linkStyling = css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: inline-flex;\n  align-self: flex-start;\n  align-items: center;\n  align-content: center;\n  font-family: ", ";\n  font-weight: bold;\n  text-decoration: none;\n\n  * {\n    transition: color 0.2s ease-in-out, fill 0.2s ease-in-out;\n    color: ", ";\n    fill: ", ";\n  }\n\n  ", " &:hover {\n    cursor: pointer;\n\n    ", ";\n  }\n"])), fonts.primary, function (props) {
  return props.color ? props.color : colorPalette.blue;
}, function (props) {
  return props.color ? props.color : colorPalette.blue;
}, function (props) {
  return props.size === 'small' ? "\n    font-size: 14rem;\n\n    span + span {\n      margin-left: 7rem;\n    }\n  " : "\n    font-size: 16rem;\n  ";
}, function (props) {
  return props.colorHover ? "\n      * {\n        color: ".concat(props.colorHover, ";\n        fill: ").concat(props.colorHover, ";\n      }\n    ") : "\n      * {\n        color: ".concat(props.color ? darken(props.color, 15) : colorPalette.blue, ";\n        fill: ").concat(props.color ? darken(props.color, 15) : colorPalette.darkBlue, ";\n      }\n    ");
});
var PageRouteLink = styled(function (_ref) {
  var colorHover = _ref.colorHover,
    props = _objectWithoutProperties(_ref, _excluded);
  return __jsx(Link, _extends({}, props, {
    "data-id": "buttons_ButtonSecondary_Link"
  }));
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", ";\n"])), linkStyling);
var NormalLink = styled.a(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", ";\n"])), linkStyling);
var ButtonLink = styled.button(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", ";\n"])), linkStyling);