import type { AppProps } from 'next/app'
import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { endpointConfig, globalConfig } from '@/constants/apiDataConfig'
import { configureApiData } from '@/redux/apiData'
import { storeWrapper } from '@/redux/store'
import Head from 'next/head'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QUERY_CLIENT } from '@/constants/queryClient'
import GlobalStyle from '@/styles/globalStyles'
import ErrorBoundary from '@/atoms/ErrorBoundaryWithSentry'
import { devLog } from '@/services/devLog'
import TagManager from '@/components/TagManager'
import packageJson from '../package.json'

// log the version while we are setting up and debugging deployment processes
// eslint-disable-next-line no-console
console.log(packageJson.version, process.env.NEXT_PUBLIC_ENV)
devLog('Debug on')

const App = ({ Component, ...rest }: AppProps) => {
  const [initializing, setInitializing] = useState(true)

  const {
    store,
    props: { pageProps },
  } = storeWrapper.useWrappedStore(rest)

  useEffect(() => {
    // @ts-ignore (ignore endpointConfig typing mismatch for now)
    store.dispatch(configureApiData(globalConfig, endpointConfig))
    setInitializing(false)
  }, [store])

  return initializing ? null : (
    <Provider store={store}>
      <Head>
        <title key="title">Homestudios</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta
          name="theme-color"
          content="#ffffff"
        />
        <link
          rel="manifest"
          href="/manifest.json"
        />
        <link
          rel="shortcut icon"
          href="/favicon.ico"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link
          rel="manifest"
          href="/site.webmanifest"
        />
        <link
          rel="mask-icon"
          href="/safari-pinned-tab.svg"
          color="#ff6600"
        />
        <meta
          name="msapplication-TileColor"
          content="#ffffff"
        />
        <meta
          name="theme-color"
          content="#ffffff"
        />
      </Head>
      <GlobalStyle />
      <ErrorBoundary fullscreen>
        <QueryClientProvider client={QUERY_CLIENT}>
          <TagManager />
          {/* @ts-ignore (ignore error due to to-be-removed hoist-non-react-statics) */}
          <Component {...pageProps} />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ErrorBoundary>
    </Provider>
  )
}

export default App
