import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { colorPalette } from './../../../../styles/styleGuide';
import Label from './Label';
import ErrorMessage from './ErrorMessage';
var defaultProps = {};
var Radio = function Radio(_ref) {
  var id = _ref.id,
    name = _ref.name,
    value = _ref.value,
    label = _ref.label,
    isValid = _ref.isValid,
    required = _ref.required,
    validationMessage = _ref.validationMessage,
    defaultChecked = _ref.defaultChecked,
    defaultValue = _ref.defaultValue,
    disabled = _ref.disabled,
    onChange = _ref.onChange;
  return __jsx(Container, {
    "data-id": "fieldTypes_Radio_Container"
  }, __jsx(RadioLabel, {
    "data-id": "fieldTypes_Radio_RadioLabel"
  }, __jsx(Input, {
    name: name,
    type: "radio",
    value: value,
    defaultValue: defaultValue,
    checked: defaultChecked,
    onChange: onChange,
    disabled: disabled,
    "data-id": "fieldTypes_Radio_Input"
  }), __jsx(FakeRadio, {
    "data-id": "fieldTypes_Radio_FakeRadio"
  }, __jsx(BlueDot, {
    "data-id": "fieldTypes_Radio_BlueDot"
  })), __jsx(Label, {
    name: id,
    content: label,
    required: required,
    mode: "span",
    "data-id": "fieldTypes_Radio_Label"
  })), validationMessage && __jsx(ErrorMessage, {
    isValid: isValid,
    message: validationMessage,
    "data-id": "fieldTypes_Radio_ErrorMessage"
  }));
};
Radio.defaultProps = defaultProps;
export default Radio;
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  position: relative;\n"])));
var RadioLabel = styled.label(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n"])));
var FakeRadio = styled.span(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-shrink: 0;\n  width: 24rem;\n  height: 24rem;\n  border-radius: 50%;\n  align-items: center;\n  justify-content: center;\n  border: solid 1rem ", ";\n  margin-right: 8rem;\n"])), colorPalette.beige);
var Input = styled.input(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: none;\n  \n  + span > span {\n    display: none;\n  }\n  \n  &:checked + span > span {\n    display: flex;\n  }\n  \n  &:disabled {\n    // Circle\n    + span {\n      background: ", ";\n      border: solid 1rem ", ";\n    }\n    // Text\n    + span + span {\n      color: ", ";\n      text-decoration: line-through;\n    }\n  }\n"])), colorPalette.background, colorPalette.lightBeige, colorPalette.lightBeige);
var BlueDot = styled.span(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: flex;\n  background: ", ";\n  width: 10rem;\n  height: 10rem;\n  border-radius: 50%;\n"])), colorPalette.blue);