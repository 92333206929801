var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from '../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.blue
};
var Download = function Download(_ref) {
  var color = _ref.color;
  return __jsx("svg", {
    style: {
      width: '22px',
      height: '19px'
    },
    viewBox: "0 0 22 19",
    version: "1.1",
    "data-id": "icons_Download_svg"
  }, __jsx("title", {
    "data-id": "icons_Download_title"
  }, "Download icoon"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fillRule: "evenodd",
    "data-id": "icons_Download_g"
  }, __jsx("g", {
    transform: "translate(-330.000000, -1524.000000)",
    fill: color,
    "data-id": "icons_Download_g_3"
  }, __jsx("g", {
    transform: "translate(230.000000, 100.000000)",
    "data-id": "icons_Download_g_4"
  }, __jsx("g", {
    transform: "translate(100.000000, 1421.000000)",
    "data-id": "icons_Download_g_5"
  }, __jsx("g", {
    transform: "translate(11.000000, 12.500000) rotate(90.000000) translate(-11.000000, -12.500000) translate(1.500000, 1.500000)",
    "data-id": "icons_Download_g_6"
  }, __jsx("path", {
    d: "M7.76109881,9.051119 L7.76109881,2.23142104 L10.7610988,2.23142104 L10.7610988,8.81727181 L11.8775853,7.70078531 C12.4956146,7.08275603 13.4701232,7.07892207 14.0939389,7.66645496 L14.0975444,7.66284944 L15.2610988,8.82640387 L13.346646,10.7408567 L13.3438609,10.7380715 L10.3198756,13.7620568 C9.99286,14.0890723 9.56603925,14.2441288 9.14417521,14.2306079 L9.14417521,14.2306079 C8.72231117,14.2441288 8.29549043,14.0890723 7.96847486,13.7620568 L4.92687869,10.7204606 L3.0326296,8.82639314 L4.13394739,7.72507534 L4.13460889,7.72573684 C4.14263371,7.71736432 4.15076778,7.70905552 4.15901112,7.70081218 C4.78082324,7.07900006 5.78091644,7.07093663 6.41076512,7.70078531 L7.76109881,9.051119 Z M1.26264058,17.2626406 L16.768579,17.2626406 L16.768579,10.231421 L19.768579,10.231421 L19.768579,17.7626406 C19.768579,19.1433525 18.6492908,20.2626406 17.268579,20.2626406 L0.762640584,20.2626406 C-0.618071291,20.2626406 -1.73735942,19.1433525 -1.73735942,17.7626406 L-1.73735942,10.231421 L1.26264058,10.231421 L1.26264058,17.2626406 Z",
    transform: "translate(9.015610, 11.247031) rotate(-90.000000) translate(-9.015610, -11.247031) ",
    "data-id": "icons_Download_path"
  })))))));
};
Download.defaultProps = defaultProps;
export default Download;