export var months = {
  1: {
    shortName: 'Jan',
    longName: 'Januari',
    monthNumber: 1
  },
  2: {
    shortName: 'Feb',
    longName: 'Februari',
    monthNumber: 2
  },
  3: {
    shortName: 'Mar',
    longName: 'Maart',
    monthNumber: 3
  },
  4: {
    shortName: 'Apr',
    longName: 'April',
    monthNumber: 4
  },
  5: {
    shortName: 'Mei',
    longName: 'Mei',
    monthNumber: 5
  },
  6: {
    shortName: 'Jun',
    longName: 'Juni',
    monthNumber: 6
  },
  7: {
    shortName: 'Jul',
    longName: 'Juli',
    monthNumber: 7
  },
  8: {
    shortName: 'Aug',
    longName: 'Augustus',
    monthNumber: 8
  },
  9: {
    shortName: 'Sep',
    longName: 'September',
    monthNumber: 9
  },
  10: {
    shortName: 'Okt',
    longName: 'Oktober',
    monthNumber: 10
  },
  11: {
    shortName: 'Nov',
    longName: 'November',
    monthNumber: 11
  },
  12: {
    shortName: 'Dec',
    longName: 'December',
    monthNumber: 12
  }
};