var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.green,
  width: 16,
  height: 12
};
var Check = function Check(_ref) {
  var width = _ref.width,
    height = _ref.height,
    color = _ref.color;
  return __jsx("svg", {
    style: {
      width: "".concat(String(width), "px"),
      height: "".concat(String(height), "px")
    },
    viewBox: "0 0 16 12",
    version: "1.1",
    "data-id": "icons_Check_svg"
  }, __jsx("title", {
    "data-id": "icons_Check_title"
  }, "Vink icoon"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_Check_g"
  }, __jsx("g", {
    transform: "translate(-319.000000, -3010.000000)",
    fillRule: "nonzero",
    fill: color,
    "data-id": "icons_Check_g_3"
  }, __jsx("g", {
    transform: "translate(230.000000, 100.000000)",
    "data-id": "icons_Check_g_4"
  }, __jsx("g", {
    transform: "translate(88.000000, 2519.000000)",
    "data-id": "icons_Check_g_5"
  }, __jsx("path", {
    d: "M6.80418909,397.89239 L4.48642447,395.532827 C4.01123628,395.055683 3.23856659,395.055683 2.76166733,395.534538 L1,397.304272 L6.78047857,403.015574 L16.5699908,393.144419 L14.8080791,391.37493 C14.332402,390.896809 13.5594879,390.895342 13.0833219,391.373219 L6.80418909,397.89239 Z",
    id: "icon/checkmark/themakleur1",
    "data-id": "icons_Check_path"
  }))))));
};
Check.defaultProps = defaultProps;
export default Check;