/**
 * Entity relations specified using normalizr, for automatic normalization of data coming from API.
 */
import { schema } from 'normalizr';
export var settingsSchema = new schema.Entity('settings', {}, {
  idAttribute: function idAttribute(settings) {
    return settings.projectSlug + '/' + settings.env;
  }
});
export var planningItemSchema = new schema.Entity('planningItems', {}, {
  idAttribute: function idAttribute(item) {
    return item.uniqueId ? item.uniqueId : "action_".concat(item.id);
  } // the 'todo' POST call doesn't return the uniqueId key that the 'block' GET call returns for items so we add it manually
});
export var blockSchema = new schema.Entity('blocks', {
  pastItems: [planningItemSchema],
  futureItems: [planningItemSchema],
  items: [planningItemSchema]
});
export var pageSchema = new schema.Entity('pages', {
  blocks: [blockSchema]
}, {
  idAttribute: function idAttribute(page) {
    return page.pagePath;
  }
});
export var articleSchema = new schema.Entity('articles', {}, {
  idAttribute: function idAttribute(article) {
    return article.projectSlug + '/' + article.env + '/' + article.slug;
  }
});
export var houseTypeSchema = new schema.Entity('houseTypes', {}, {
  idAttribute: function idAttribute(houseType) {
    return houseType.projectSlug && houseType.env ? houseType.projectSlug + '/' + houseType.env + '/' + houseType.slug : undefined;
  }
});
export var footerPageSchema = new schema.Entity('footerPages');
export var formSchema = new schema.Entity('forms');
export var activationSchema = new schema.Entity('activationCode');
export var hotspotOptionsSchema = new schema.Entity('hotspotOptions');
export var notificationsSchema = new schema.Entity('notifications');
export var profileSchema = new schema.Entity('profile');
export var projectShowcaseSchema = new schema.Entity('projectShowcase');
export var feedbackSchema = new schema.Entity('feedback');
export var wishListOption = new schema.Entity('wishListOptions');
export var wishListSubcategorySchema = new schema.Entity('wishListSubcategories', {
  options: [wishListOption]
});
export var wishListCategorySchema = new schema.Entity('wishListCategories', {
  subcategories: [wishListSubcategorySchema]
});
export var wishListNotificationsSchema = new schema.Entity('wishListNotifications');
export var wishListSchema = new schema.Entity('wishLists', {
  categories: [wishListCategorySchema],
  notifications: [wishListNotificationsSchema]
});
export var smallWishListSchema = new schema.Entity('wishListsSmall');
export var wishListModalCategorySchema = new schema.Entity('wishListModalCategory', {}, {
  idAttribute: 'categoryId'
});
export var wishListModalSubCategorySchema = new schema.Entity('wishListModalSubCategory', {}, {
  idAttribute: 'subcategoryId'
});
export var wishListModalProductOptionSchema = new schema.Entity('wishListModalProductOption', {}, {
  idAttribute: 'optionId'
});
export var productOptionRequestOrderSchema = new schema.Entity('productOptionRequestOrder', {}, {
  idAttribute: function idAttribute(order) {
    return order.id;
  }
});
export var productOptionOrderSchema = new schema.Entity('productOptionOrder', {}, {
  idAttribute: function idAttribute() {
    return 'orderId';
  }
});
export var verificationStatusSchema = new schema.Entity('verificationStatus', {}, {
  idAttribute: function idAttribute() {
    return 'status';
  }
});
export var verificationTokenSchema = new schema.Entity('verificationToken', {}, {
  idAttribute: function idAttribute() {
    return 'token';
  }
});