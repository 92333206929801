import { devLog } from '@/services/devLog'

/**
 *  Format an CMS image URL with dimension placeholders.
 */
const formatCmsImageUrl = (
  width: number,
  height: number,
  imageUrl?: string,
  rectangular = false
) => {
  if (!imageUrl) {
    return undefined
  }

  // Cast to number to make sure it is always correctly given to the backend
  const widthValue = Number(width)
  const heightValue = Number(height)

  try {
    const url = new URL(imageUrl)

    return url.pathname.split('/').reduce((acc, curr, index, arr) => {
      if (!curr) return `${acc}`

      if (curr.match(/([0-9]*x[0-9]*)|__WIDTH__x__HEIGHT__/g)) {
        return `${acc}${Math.ceil(widthValue)}x${Math.ceil(
          rectangular ? widthValue : heightValue
        )}/`
      }

      return index === arr.length - 1 ? `${acc + curr}` : `${acc + curr}/`
    }, `${url.origin}/`)
  } catch (e) {
    devLog(e)
    return undefined
  }
}

export default formatCmsImageUrl
