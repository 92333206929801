import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject;
var __jsx = React.createElement;
import * as React from 'react';
import styled from 'styled-components';
import { textStyles, colorPalette } from '../../../styles/styleGuide';
var Heading4 = function Heading4(_ref) {
  var children = _ref.children;
  return __jsx(Heading, {
    "data-id": "richText_Heading4_Heading"
  }, children);
};
export default Heading4;
var Heading = styled.h4(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n  color: ", ";\n  margin-top: 0;\n  margin-bottom: 15rem;\n"])), textStyles.subTitle, colorPalette.headerGrey);