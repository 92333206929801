export type Sizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'none'

export const SIZE_MAP: Record<Sizes, number> = {
  none: 0,
  xs: 4,
  sm: 8,
  md: 16,
  lg: 24,
  xl: 32,
  xxl: 56,
}
