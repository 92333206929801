import { createStore, applyMiddleware, compose as defaultCompose, Store } from 'redux'
import thunk from 'redux-thunk'
import { apiMiddleware } from 'redux-api-middleware'
import { createWrapper, MakeStore } from 'next-redux-wrapper'
import { composeWithDevTools } from '@redux-devtools/extension'
import reducer, { State } from './index'

const configureStore: MakeStore<Store<State>> = (initialState = {}) => {
  const middlewares = [thunk, apiMiddleware]
  const compose = process.env.NODE_ENV === 'development' ? composeWithDevTools : defaultCompose

  // Ignore typing issue due to deprecated Redux set up. Won't fix, because time is better spent migrating to RTK
  // @ts-ignore
  return createStore(reducer, initialState, compose(applyMiddleware(...middlewares)))
}

export const storeWrapper = createWrapper<Store>(configureStore)

export default configureStore
