/* eslint no-console: 0 */
var __DEV__ = process.env.NODE_ENV === 'development';
var isObjectFormData = function isObjectFormData(object) {
  return Object.prototype.toString.call(object) === '[object FormData]';
};

/**
 * Get the headers based on request properties, adds:
 * - Content Type json if a body is set
 * - Authorization if auth token is set
 * @param requestProperties
 * @returns {{}}
 */
var getHeaders = function getHeaders(requestProperties) {
  var headers = requestProperties.headers || {};
  if ('body' in requestProperties && !isObjectFormData(requestProperties.body)) {
    // When a request is form-data/multipart, do NOT set the 'Content-Type' header at all.
    // When left undefined, the browser will automatically take care of the header
    // and the required form boundary.
    headers['Content-Type'] = 'application/json';
  }
  return headers;
};

/**
 * Execute a request. Body object will be converted to JSON.
 *
 * @param url
 * @param requestProperties
 * @returns {Promise<HandledResponse>} Resolves with response, response body (json parsed, if present). Rejects with an Error if
 * connection fails.
 */
export default (function (url) {
  var requestProperties = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (__DEV__) {
    console.log('Executing request: ' + url);
  }
  requestProperties.headers = getHeaders(requestProperties);
  if (typeof requestProperties.body !== 'string' && !isObjectFormData(requestProperties.body)) {
    requestProperties.body = JSON.stringify(requestProperties.body);
  }
  return new Promise(function (resolve, reject) {
    var onRequestSuccess = function onRequestSuccess(response) {
      if (__DEV__) {
        console.log("Request successful (".concat(response.status, "): ") + url);
      }
      if (response.status === 204 || response.headers.get('content-length') === 0) {
        // 204: no content
        resolve({
          response: response,
          body: {}
        });
      } else {
        response.json().then(function (body) {
          return resolve({
            response: response,
            body: body
          });
        }, function () {
          return resolve({
            response: response,
            body: {}
          });
        });
      }
    };
    var onRequestError = function onRequestError(error) {
      if (__DEV__) {
        console.log("Request failed: ".concat(url));
        console.error(error);
      }
      reject(error);
    };
    fetch(url, requestProperties).then(onRequestSuccess, onRequestError);
  });
});