import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
/* eslint-disable no-useless-computed-key */export var PROJECT_UPDATE_NOTIFICATIONS = 'projectUpdateNotifications';
export var ARTICLE_NOTIFICATIONS = 'articleNotifications';
export default (function (link, email) {
  return [{
    categoryTitle: 'Mijn persoonlijk profiel',
    categoryDescription: 'Beheer hier je persoonlijk profiel en de toegang tot je Homestudios account.',
    options: [{
      id: 'image',
      name: 'image',
      label: 'Profielfoto',
      title: 'Profielfoto',
      type: 'imageUpload',
      required: false,
      excludeFromSubmit: true,
      uploadType: 'user'
    }, {
      id: 'gender',
      name: 'gender',
      label: 'Spreek mij aan als:',
      required: false,
      title: 'Spreek mij aan als:',
      type: 'radio',
      choices: [{
        key: 'f',
        value: 'f',
        label: 'Mevrouw'
      }, {
        key: 'm',
        value: 'm',
        label: 'Meneer'
      }, {
        key: 'n',
        value: 'n',
        label: 'Geen'
      }]
    }, {
      id: 'name',
      name: 'name',
      label: 'Aanspreeknaam',
      title: 'Aanspreeknaam',
      required: true,
      type: 'text',
      maxLength: 100
    }, {
      id: 'firstName',
      name: 'firstName',
      label: 'Voornaam',
      title: 'Voornaam',
      required: false,
      type: 'text'
    }, {
      id: 'lastName',
      name: 'lastName',
      label: 'Achternaam',
      title: 'Achternaam',
      required: false,
      type: 'text'
    }, {
      id: 'emailAddress',
      name: 'emailAddress',
      type: 'readOnly',
      title: '',
      label: "\n          [p]\n            [strong]E-mailadres[/strong][br][/br]\n            ".concat(email, "[br][/br]\n            [strong][pagenode=\"0\" type=\"link\"]E-mailadres aanpassen[/pagenode][/strong]\n          [/p]\n          "),
      pages: _defineProperty({}, 0, link || {}),
      excludeFromSubmit: true
    }, {
      id: 'articleNotifications',
      type: 'checkboxBoolean',
      name: 'articleNotifications',
      label: 'Ontvang nieuwsbrief notificaties',
      required: false
    }, {
      id: 'projectUpdateNotifications',
      type: 'checkboxBoolean',
      name: 'projectUpdateNotifications',
      label: 'Ontvang projectupdates notificaties',
      required: false
    }, {
      id: 'passwordLink',
      name: 'passwordLink',
      type: 'passwordLink',
      label: 'Wachtwoord',
      title: 'Wachtwoord opnieuw instellen',
      excludeFromSubmit: true
    }]
  }];
});