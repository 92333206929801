import { FC } from 'react'
import styled from 'styled-components'
import { colors } from '@/styles/colors'
import radii from '@/styles/radii'

const IconContainer = styled.div`
  ${radii.circle}
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24rem;
  height: 24rem;
  background-color: ${colors.white};
  color: ${colors.gray40};
  flex-grow: 0;
  flex-shrink: 0;
`

const Icon = styled.div`
  font-size: 12rem;
  line-height: 12rem;
  font-weight: 700;
`

const InformationIcon: FC = () => {
  return (
    <IconContainer>
      <Icon>!</Icon>
    </IconContainer>
  )
}

export default InformationIcon
