import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
var __jsx = React.createElement;
import React from 'react';
import styled, { css } from 'styled-components';
import { colorPalette, fonts } from '../../../../styles/styleGuide';
import { fromBreakpoint } from '../../../../styles/breakpoints';
var defaultProps = {
  mode: 'label'
};
var Label = function Label(_ref) {
  var children = _ref.children,
    mode = _ref.mode,
    name = _ref.name,
    color = _ref.color,
    required = _ref.required,
    content = _ref.content;
  return mode === 'label' ? __jsx(FormLabel, {
    htmlFor: name,
    color: color,
    "data-id": "fieldTypes_Label_FormLabel"
  }, content || children, required ? '*' : '') : __jsx(SpanLabel, {
    color: color,
    "data-id": "fieldTypes_Label_SpanLabel"
  }, content || children, required ? '*' : '');
};
Label.defaultProps = defaultProps;
export default Label;
var styles = css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  font-family: ", ";\n  width: 100%;\n  font-size: 16rem;\n  color: ", ";\n  margin-left: 0;\n  font-style: italic;\n  cursor: pointer;\n  ", " &:after {\n    content: '';\n    display: block;\n    position: absolute;\n    width: 0;\n    height: 2rem;\n    top: 60rem;\n    background: ", ";\n    transition: width 0s;\n  }\n\n  > a {\n    color: ", ";\n    text-decoration: none;\n    transition: color 0.3s ease-in-out;\n\n    &:hover {\n      color: ", ";\n      text-decoration: underline;\n    }\n  }\n"])), fonts.primary, function (props) {
  return props.color ? props.color : colorPalette.beige;
}, fromBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    font-size: 18rem;\n  "]))), colorPalette.beige, colorPalette.blue, colorPalette.darkBlue);
export var FormLabel = styled.label(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", ";\n"])), styles);
var SpanLabel = styled.span(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", ";\n"])), styles);