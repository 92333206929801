import { css } from 'styled-components'

// RADII

const radii = {
  circle: css`
    border-radius: 50%;
  `,
  container: {
    lg: css`
      border-radius: 8rem 0;
    `,
    sm: css`
      border-radius: 2rem 0;
    `,
  },
}

export default radii
