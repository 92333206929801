import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject;
var __jsx = React.createElement;
import * as React from 'react';
import styled from 'styled-components';
import { textStyles, colorPalette } from '../../../styles/styleGuide';
var Heading3 = function Heading3(_ref) {
  var children = _ref.children;
  return __jsx(Heading, {
    "data-id": "richText_Heading3_Heading"
  }, children);
};
export default Heading3;
var Heading = styled.h3(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n  color: ", ";\n  margin-top: 0;\n  margin-bottom: 20rem;\n"])), textStyles.title, colorPalette.headerGrey);