var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  direction: 'right',
  color: colorPalette.beige,
  width: 16,
  height: 9,
  scale: 1
};
var ROTATE = {
  up: 180,
  down: 0,
  left: 90,
  right: 270
};
var Caret = function Caret(_ref) {
  var width = _ref.width,
    height = _ref.height,
    direction = _ref.direction,
    color = _ref.color,
    scale = _ref.scale,
    className = _ref.className;
  return __jsx("svg", {
    viewBox: "0 0 16 9",
    style: {
      width: "".concat(String(width), "px"),
      height: "".concat(String(height), "px"),
      transform: "rotateZ(".concat(ROTATE[direction || 'down'], "deg) scale(").concat(String(scale), ")")
    },
    className: className,
    "data-id": "icons_Caret_svg"
  }, __jsx("title", {
    "data-id": "icons_Caret_title"
  }, "Pijltje icoon"), __jsx("g", {
    fill: color,
    "data-id": "icons_Caret_g"
  }, __jsx("path", {
    d: "M14.4575,0.548513514 L14.4525,0.553135135 C13.63725,-0.193621622 12.364,-0.188756757 11.5565,0.596918919 L7.98525,4.07164865 L4.41375,0.596675676 C3.59075,-0.204081081 2.284,-0.193864865 1.4715,0.596675676 C1.46075,0.607135135 1.45025,0.617594595 1.43975,0.628297297 L1.439,0.627567568 L0,2.02767568 L6.449,8.30189189 C6.87625,8.71759459 7.434,8.91486486 7.98525,8.89735135 C8.53675,8.91437838 9.09425,8.71759459 9.5215,8.30189189 L15.97775,2.02743243 L14.4575,0.548513514 L14.4575,0.548513514 Z",
    "data-id": "icons_Caret_path"
  })));
};
Caret.defaultProps = defaultProps;
export default Caret;