import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import withApiData from '../../../../services/withApiData';
import withMatch from '@/hocs/withMatch';
import { colorPalette, fonts } from './../../../../styles/styleGuide';
import { fromBreakpoint } from '../../../../styles/breakpoints';
import ButtonSecondary from '../../buttons/ButtonSecondary';
import { performApiRequest } from '../../../../redux/apiData';
import { openModal } from '../../../../redux/modal';
import get from 'lodash.get';
var connectApiData = withApiData({
  profile: 'getProfile'
}, function (ownProps) {
  return {
    profile: {
      projectSlug: ownProps.match.params.projectSlug
    }
  };
});
var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    handleClick: function handleClick(email) {
      var projectSlug = ownProps.match.params.projectSlug;
      dispatch(performApiRequest('requestPassword', {
        projectSlug: String(projectSlug)
      }, {
        email: email
      }));
      dispatch(openModal(colorPalette.beige, {
        type: 'password',
        componentProps: {
          changePassword: true
        }
      }));
    }
  };
};
var PasswordLink = function PasswordLink(_ref) {
  var profile = _ref.profile,
    label = _ref.label,
    title = _ref.title,
    handleClick = _ref.handleClick;
  return profile.data ? __jsx(Container, {
    "data-id": "fieldTypes_PasswordLink_Container"
  }, __jsx(Label, {
    "data-id": "fieldTypes_PasswordLink_Label"
  }, label), __jsx(ButtonSecondary, {
    label: title,
    type: "button",
    clickHandler: function clickHandler() {
      return handleClick(get(profile, 'data.email'));
    },
    "data-id": "fieldTypes_PasswordLink_ButtonSecondary"
  })) : null;
};
export default withMatch(connectApiData(connect(null, mapDispatchToProps)(PasswordLink)));
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin-bottom: 30rem;\n  ", ";\n\n  > button {\n    ", ";\n  }\n"])), fromBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    margin-bottom: 50rem;\n  "]))), fromBreakpoint.md(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n      font-size: 18rem;\n    "]))));
var Label = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  width: 100%;\n  margin-bottom: 0;\n  margin-left: 0;\n  font-family: ", ";\n  font-weight: bold;\n  font-size: 16rem;\n  color: ", ";\n\n  ", ";\n"])), fonts.primary, colorPalette.headerGrey, fromBreakpoint.md(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    font-size: 18rem;\n  "]))));