import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
import styled from 'styled-components';
import { textStyles, colorPalette, gridGutter, maxContentWidth } from './styleGuide';
import { fromBreakpoint } from './breakpoints';
export var WidthLimit = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  max-width: ", "rem;\n  margin-left: auto;\n  margin-right: auto;\n"])), maxContentWidth + gridGutter);
export var GridWrapper = styled(WidthLimit)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n  position: relative;\n"])));
export var GridItem = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  width: 100%;\n  padding-left: ", "rem;\n  padding-right: ", "rem;\n  box-sizing: border-box;\n\n  ", ";\n\n  ", " ", ";\n"])), gridGutter / 2, gridGutter / 2, function (props) {
  return props.whiteBackground ? "\n    background-color: white;\n    width: calc(100% - ".concat(gridGutter / 2, "rem);\n    padding-left: 0;\n    padding-right: 0;\n    margin-left: ").concat(gridGutter / 4, "rem;\n    margin-right: ").concat(gridGutter / 4, "rem;\n    margin-bottom: 40rem;\n  ") : "\n    background-color: transparent\n  ";
}, function (props) {
  return props.noGutterUntilBreakpoint ? "\n    @media (max-width: ".concat(props.noGutterUntilBreakpoint, "px) {\n      padding-left: 0;\n      padding-right: 0;\n    }\n  ") : '';
}, fromBreakpoint.md(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    width: ", "%;\n\n    ", ";\n  "])), function (props) {
  return (props.size || 3) * (100 / 3);
}, function (props) {
  return props.whiteBackground ? "\n      width: calc(100% - ".concat(gridGutter, "rem);\n      margin-left: ").concat(gridGutter / 2, "rem;\n      margin-right: ").concat(gridGutter / 2, "rem;\n    ") : "";
}));
export var Box = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  padding: 40rem;\n"])));
export var BoxHeader = styled.h3(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  margin-bottom: 10rem;\n"])), textStyles.pageTitle, colorPalette.blue);