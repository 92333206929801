import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject;
var __jsx = React.createElement;
import * as React from 'react';
import styled from 'styled-components';
import { textStyles } from '../../../styles/styleGuide';
var Paragraph = function Paragraph(_ref) {
  var children = _ref.children;
  return __jsx(ParagraphText, {
    "data-id": "richText_Paragraph_ParagraphText"
  }, children);
};
export default Paragraph;
var ParagraphText = styled.p(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", ";\n  margin-top: 0;\n  margin-bottom: 20rem;\n"])), textStyles.bodyText);