import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3;
var __jsx = React.createElement;
import * as React from 'react';
import styled from 'styled-components';
import withMatch from '@/hocs/withMatch';
import { colorPalette } from '../../../styles/styleGuide';
import { fromBreakpoint } from '../../../styles/breakpoints';
import Check from '../icons/Check';
var List = function List(_ref) {
  var match = _ref.match,
    type = _ref.type,
    children = _ref.children;
  var color = match.params.env === 'closed' ? colorPalette.lightOrange : colorPalette.green;
  return __jsx(ListItem, {
    orderedList: type === 'ol',
    color: color,
    "data-id": "richText_ListItem_ListItem"
  }, type === 'olist' && __jsx(Check, {
    color: color,
    "data-id": "richText_ListItem_Check"
  }), type === 'ul' && __jsx(Bullet, {
    color: color,
    "data-id": "richText_ListItem_Bullet"
  }), children);
};
export default withMatch(List);
var ListItem = styled.li(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin-bottom: 10rem;\n  counter-increment: item;\n\n  > svg {\n    margin-right: 15rem;\n  }\n\n  &:before {\n    display: ", ";\n    content: counter(item);\n    counter-increment: section;\n    color: ", ";\n    font-weight: bold;\n    margin-right: 20rem;\n  }\n"])), function (props) {
  return props.orderedList ? 'inline-block' : 'none';
}, function (props) {
  return props.color;
});
var Bullet = styled.span(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: inline-block;\n  width: 6rem;\n  height: 6rem;\n  border-radius: 50%;\n  background-color: ", ";\n  margin-right: 20rem;\n\n  ", "\n}\n"])), function (props) {
  return props.color;
}, fromBreakpoint.md(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    width: 8rem;\n    height: 8rem;\n  "]))));