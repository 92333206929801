import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import withMatch from '@/hocs/withMatch';
import Upload from '../icons/Upload';
import { colorPalette, fonts } from '../../../styles/styleGuide';
import { darken } from './../../../styles/utils';
import { connect } from 'react-redux';
import { getResultData } from '../../../redux/apiData';
import get from 'lodash.get';
var defaultProps = {
  buttonColor: 'none',
  labelColor: colorPalette.blue
};
var mapStateToProps = function mapStateToProps(state, ownProps) {
  return {
    styling: get(getResultData(state.apiData, 'getSettings', {
      projectSlug: String(ownProps.match.params.projectSlug),
      env: String(ownProps.match.params.env)
    }), 'styling', {})
  };
};
var ButtonUpload = function ButtonUpload(_ref) {
  var styling = _ref.styling,
    clickHandler = _ref.clickHandler,
    label = _ref.label,
    match = _ref.match;
  var _styling$button = styling.button,
    color = _styling$button === void 0 ? colorPalette.blue : _styling$button;
  var labelColor = match.params.env === 'closed' ? colorPalette.blue : color;
  return __jsx(Button, {
    type: "button",
    labelColor: labelColor,
    onClick: clickHandler,
    "data-id": "buttons_ButtonUpload_Button"
  }, __jsx(Upload, {
    color: labelColor,
    "data-id": "buttons_ButtonUpload_Upload"
  }), label);
};
ButtonUpload.defaultProps = defaultProps;
export default withMatch(connect(mapStateToProps)(ButtonUpload));
var Button = styled.button(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: inline-flex;\n  font-family: ", ";\n  font-weight: bold;\n  color: ", ";\n  padding: 10rem 0;\n  transition: color 0.2s ease-in-out;\n\n  svg {\n    margin-right: 10rem;\n\n    g {\n      fill: ", ";\n      transition: fill 0.2s ease-in-out;\n    }\n  }\n\n  &:hover {\n    color: ", ";\n\n    svg g {\n      fill: ", ";\n    }\n  }\n"])), fonts.primary, function (props) {
  return props.labelColor;
}, function (props) {
  return props.color ? props.color : colorPalette.blue;
}, function (props) {
  return props.color ? darken(props.color, 15) : colorPalette.darkBlue;
}, function (props) {
  return props.color ? darken(props.color, 15) : colorPalette.darkBlue;
});