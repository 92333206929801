import { MouseEventHandler, ReactChild } from 'react'
import { FormCheckbox, FormCheckboxContainer } from './Checkbox'
import Label from './Label'
import ErrorMessage from './ErrorMessage'

type CheckboxProps = {
  label?: string
  required?: boolean
  name: string
  value?: string
  onChange?: MouseEventHandler<HTMLInputElement>
  isValid?: boolean
  validationMessage?: string
  children?: ReactChild
  checked?: boolean
}

const CheckboxBoolean = ({
  children,
  name,
  required,
  label,
  onChange,
  value,
  isValid,
  validationMessage,
  checked,
}: CheckboxProps) => {
  return (
    <FormCheckboxContainer>
      <FormCheckbox
        id={name}
        required={required}
        name={name}
        type="checkbox"
        value={value}
        onClick={onChange}
        checked={checked}
      />
      <Label
        name={name}
        required={required}
      >
        {children || label}
      </Label>
      {validationMessage && (
        <ErrorMessage
          isValid={isValid}
          message={validationMessage}
        />
      )}
    </FormCheckboxContainer>
  )
}

export default CheckboxBoolean
