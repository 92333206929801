import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject;
var __jsx = React.createElement;
import * as React from 'react';
import styled from 'styled-components';
import { textStyles, colorPalette } from '../../../styles/styleGuide';
var ListChecked = function ListChecked(_ref) {
  var children = _ref.children;
  return __jsx(List, {
    "data-id": "richText_ListChecked_List"
  }, children);
};
export default ListChecked;
var List = styled.ul(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  padding: 0;\n  margin-top: 0;\n  margin-bottom: 40rem;\n  list-style: none;\n"])), textStyles.bodyText, colorPalette.textGrey);