export var hex2rgbArray = function hex2rgbArray(hex) {
  if (!hex || hex.length < 4) {
    return [];
  }
  return (hex.slice(-6).match(/.{1,2}/g) || []).map(function (colorChannel) {
    return parseInt(colorChannel, 16);
  });
};
export var rgbArray2hslArray = function rgbArray2hslArray(rgbArray) {
  var r = rgbArray[0] / 255;
  var g = rgbArray[1] / 255;
  var b = rgbArray[2] / 255;
  var max = Math.max(r, g, b);
  var min = Math.min(r, g, b);
  var h;
  var s;
  var l;
  h = s = l = (max + min) / 2;
  if (max === min) {
    h = s = 0;
  } else {
    var _d = max - min;
    s = l > 0.5 ? _d / (2 - max - min) : _d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / _d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / _d + 2;
        break;
      case b:
      default:
        h = (r - g) / _d + 4;
        break;
    }
    h /= 6;
  }
  return [h * 360 + 0.5 | 0, (s * 100 + 0.5 | 0) + '%', (l * 100 + 0.5 | 0) + '%'];
};
export var hex2rgb = function hex2rgb(hex) {
  var opacity = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  if (hex.indexOf('rgb') === 0) {
    // not actually hex
    return hex;
  }
  return "rgba(".concat((hex2rgbArray(hex) || []).join(','), ", ").concat(opacity, ")");
};
export var hex2hsl = function hex2hsl(hex) {
  var rgb = hex2rgbArray(hex);
  return rgb ? rgbArray2hslArray(rgb) : '';
};
export var darken = function darken(hex) {
  var percentage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  if (!hex) return '';
  var hsl = hex2hsl(hex);
  var lightness = Number(hsl[2].replace('%', ''));
  var adjustedLightness = lightness - percentage > 0 ? "".concat(lightness - percentage, "%") : '0%';
  return "hsl(".concat(hsl[0], ", ").concat(hsl[1], ", ").concat(adjustedLightness, ")");
};
export var lighten = function lighten(hex) {
  var percentage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  if (!hex) return '';
  var hsl = hex2hsl(hex);
  var lightness = Number(hsl[2].replace('%', ''));
  var adjustedLightness = lightness + percentage < 101 ? "".concat(lightness + percentage, "%") : '100%';
  return "hsl(".concat(hsl[0], ", ").concat(hsl[1], ", ").concat(adjustedLightness, ")");
};