import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import Download from '../icons/Download';
import withMatch from '@/hocs/withMatch';
import { colorPalette, fonts } from '../../../styles/styleGuide';
import { darken } from './../../../styles/utils';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { getResultData } from '../../../redux/apiData';
var defaultProps = {
  content: 'Download'
};
var mapStateToProps = function mapStateToProps(state, ownProps) {
  return {
    styling: get(getResultData(state.apiData, 'getSettings', {
      projectSlug: String(ownProps.match.params.projectSlug),
      env: String(ownProps.match.params.env)
    }), 'styling', {})
  };
};
var ButtonDownload = function ButtonDownload(_ref) {
  var fileId = _ref.fileId,
    content = _ref.content,
    match = _ref.match,
    styling = _ref.styling,
    files = _ref.files,
    link = _ref.link,
    image = _ref.image;
  var linkItem = fileId && files ? files[fileId] : link;
  var _styling$button = styling.button,
    themeColor = _styling$button === void 0 ? colorPalette.blue : _styling$button;
  var color = image ? colorPalette.white : match.params.env === 'closed' ? colorPalette.blue : themeColor;
  return __jsx(Container, {
    "data-id": "buttons_ButtonDownload_Container"
  }, image ? __jsx(Link, {
    target: "_blank",
    href: link,
    color: color,
    download: true,
    "data-id": "buttons_ButtonDownload_Link"
  }, __jsx(Download, {
    "data-id": "buttons_ButtonDownload_Download"
  }), content) : __jsx(Link, {
    target: "_blank",
    href: linkItem,
    color: color,
    "data-id": "buttons_ButtonDownload_Link"
  }, __jsx(Download, {
    "data-id": "buttons_ButtonDownload_Download"
  }), content));
};
ButtonDownload.defaultProps = defaultProps;
export default withMatch(connect(mapStateToProps)(ButtonDownload));
var Container = styled.span(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  align-self: flex-start;\n  margin-bottom: 10rem;\n"])));
var Link = styled.a(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: inline-flex;\n  align-self: flex-start;\n  align-items: center;\n  color: ", ";\n  font-family: ", ";\n  font-weight: bold;\n  text-decoration: none;\n  transition: color 0.2s ease-in-out;\n\n  svg {\n    margin-right: 10rem;\n\n    g {\n      fill: ", ";\n      transition: fill 0.2s ease-in-out;\n    }\n  }\n\n  &:hover {\n    color: ", ";\n\n    svg g {\n      fill: ", ";\n    }\n  }\n"])), function (props) {
  return props.color ? props.color : colorPalette.blue;
}, fonts.primary, function (props) {
  return props.color ? props.color : colorPalette.blue;
}, function (props) {
  return props.color ? darken(props.color, 15) : colorPalette.darkBlue;
}, function (props) {
  return props.color ? darken(props.color, 15) : colorPalette.darkBlue;
});