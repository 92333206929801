export enum PageType {
  article,
  houseType,
  footer,
  wishList,
  password,
  page,
  static,
}

export enum StaticPageSlug {
  app = 'app',
}
