import { ApiDataRequest, ApiDataState } from '@/redux/apiData'

export const getAllRequestsForProject = (
  apiDataState: ApiDataState,
  endpointKey: string,
  projectSlug: string
): { [requestKey: string]: ApiDataRequest } => {
  const result: { [requestKey: string]: ApiDataRequest } = {}
  Object.keys(apiDataState.requests).forEach((key) => {
    if (key.indexOf(endpointKey) === 0 && key.includes(`projectSlug=${projectSlug}`)) {
      result[key] = apiDataState.requests[key]
    }
  })
  return result
}
