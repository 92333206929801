import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { fonts, colorPalette } from './../../../../styles/styleGuide';
import { fromBreakpoint } from '../../../../styles/breakpoints';
var defaultProps = {};
var BigFieldLabel = function BigFieldLabel(_ref) {
  var label = _ref.label,
    required = _ref.required;
  return __jsx(ChoiceGroupTitle, {
    "data-id": "fieldTypes_BigFieldLabel_ChoiceGroupTitle"
  }, label, required ? '*' : '');
};
BigFieldLabel.defaultProps = defaultProps;
export default BigFieldLabel;
var ChoiceGroupTitle = styled.span(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: block;\n  font-family: ", ";\n  font-size: 16rem;\n  color: ", ";\n  font-style: italic;\n  ", ";\n"])), fonts.primary, colorPalette.beige, fromBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    font-size: 18rem;\n  "]))));