import ErrorBlock from '@/components/ErrorBlock'
import { getErrorBlockProps } from '@/constants/errorMessages'
import { ReactNode } from 'react'
import InlineError from '@/molecules/InlineError'
import { ErrorBoundary } from '@sentry/nextjs'

type Props = {
  fullscreen?: boolean
  children?: ReactNode
  silent?: boolean
}
const ErrorBoundaryWithSentry = ({ children, fullscreen, silent = false }: Props) => {
  const fallback = fullscreen ? <ErrorBlock {...getErrorBlockProps()} /> : <InlineError />

  // @ts-ignore
  return <ErrorBoundary fallback={silent ? null : fallback}>{children}</ErrorBoundary>
}

export default ErrorBoundaryWithSentry
