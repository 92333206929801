import { combineReducers } from 'redux'
import { EqualityFn, useSelector as useReduxSelector } from 'react-redux'
// eslint-disable-next-line
import menu from './menu'
import type { MenuState } from './menu'
import modal from './modal'
import type { ModalState } from './modal'
// eslint-disable-next-line
import apiData from './apiData'
import type { ApiDataState } from './apiData'
import type { HouseDesignState } from './houseDesign'
import houseDesign from './houseDesign'
import redirect from './redirect'
import globalApiError from './globalApiError'
import type { GlobalApiErrorState } from './globalApiError'

export type State = {
  menu: MenuState
  modal: ModalState
  apiData: ApiDataState
  globalApiError: GlobalApiErrorState
  houseDesign: HouseDesignState
}

/**
 * Typed version of the useSelector hook.
 */
export const useSelector = <Selected>(
  select: (state: State) => Selected,
  equalityFn?: EqualityFn<Selected>
) => useReduxSelector(select, equalityFn)

export default combineReducers({
  redirect,
  menu,
  modal,
  apiData,
  globalApiError,
  houseDesign,
})
