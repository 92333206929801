var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.blue
};
var GoToCaret = function GoToCaret(_ref) {
  var color = _ref.color;
  return __jsx("svg", {
    width: "20px",
    height: "20px",
    viewBox: "0 0 20 20",
    "data-id": "icons_GoToCaret_svg"
  }, __jsx("title", {
    "data-id": "icons_GoToCaret_title"
  }, "Go To Caret"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    transform: "translate(-240.000000, -238.000000)",
    "data-id": "icons_GoToCaret_g"
  }, __jsx("g", {
    fill: color,
    "data-id": "icons_GoToCaret_g_3"
  }, __jsx("g", {
    transform: "translate(100.000000, 236.000000)",
    "data-id": "icons_GoToCaret_g_4"
  }, __jsx("g", {
    transform: "translate(140.000000, 2.000000)",
    "data-id": "icons_GoToCaret_g_5"
  }, __jsx("path", {
    d: "M10.5066505,5.91723796 L10.5094356,5.91445279 L8.59498283,4 L7.4314284,5.16355443 L7.43503392,5.16715994 C6.84750103,5.79097565 6.85133498,6.76548422 7.46936426,7.3835135 L10.2027744,10.1169236 L7.46936426,12.8503337 C6.83951559,13.4801824 6.84757902,14.4802756 7.46939114,15.1020877 C7.47763448,15.110331 7.48594328,15.1184651 7.4943158,15.1264899 L7.4936543,15.1271514 L8.59497209,16.2284692 L10.4891304,14.3343109 L13.5306357,11.292624 C13.8576513,10.9656084 14.0127077,10.5387876 13.9991869,10.1169236 C14.0127077,9.69505955 13.8576513,9.26823881 13.5306357,8.94122324 L10.5066505,5.91723796 Z M0,3.99922997 C0,1.79051625 1.79246765,0 3.99922997,0 L20,0 L20,16.00077 C20,18.2094838 18.2075324,20 16.00077,20 L0,20 L0,3.99922997 Z",
    "data-id": "icons_GoToCaret_path"
  }))))));
};
GoToCaret.defaultProps = defaultProps;
export default GoToCaret;