import { ComponentType } from 'react'
import useMatch from '@/hooks/useMatch'
import { NextRouter, useRouter } from 'next/router'
import { Match } from '@/types/match'

export type WithMatchProps = {
  match: Match
  router: NextRouter
}

const withMatch =
  <P extends object>(WrappedComponent: ComponentType<P & WithMatchProps>) =>
  (props: P) => {
    const router = useRouter()
    const { isReady } = router
    const { loading, error, match } = useMatch()
    if (loading || !isReady || error || !router) {
      return null
    }
    return (
      // @ts-ignore (ignore error due to to-be-removed hoist-non-react-statics)
      <WrappedComponent
        {...props}
        match={match}
        router={router}
      />
    )
  }

export default withMatch
