import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { colors } from '@/styles/colors'
import { fontStyles, fontWeight } from '@/styles/fonts'
import { SIZE_MAP } from '@/styles/spacing'

export type TextVariant = 'brand' | 'white' | 'gray10' | 'gray20' | 'gray25' | 'gray30' | 'gray40'

type Size = 'sm' | 'md' | 'lg'

type Props = {
  text?: string
  variant?: TextVariant
  children?: ReactNode
  bold?: boolean
  italic?: boolean
  inline?: boolean
  size?: Size
  withMargin?: boolean
}

type StyleProps = {
  variant: TextVariant
  bold: boolean
  italic: boolean
  size: Size
  withMargin: boolean
}

const getStyles = ({ size, variant, bold, italic, withMargin }: StyleProps) => css`
  ${fontStyles.body[size]};
  padding: 0;
  color: ${colors[variant]};
  ${bold ? `font-weight: ${fontWeight.bold};` : ''}
  ${italic ? `font-style: italic;` : ''}
  margin: ${withMargin ? `0 0 ${SIZE_MAP.md}rem` : 0};
`

const StyledP = styled.p<StyleProps>`
  ${getStyles}
`

const StyledSpan = styled.span<StyleProps>`
  ${getStyles}
`

const Inline = styled.span`
  display: inline-block;
`

const Text = ({
  children,
  text,
  size = 'md',
  variant = 'gray40',
  bold = false,
  italic = false,
  inline = false,
  withMargin = false,
}: Props) => {
  const BodyText = inline ? StyledSpan : StyledP
  return (
    <BodyText
      variant={variant}
      bold={bold}
      italic={italic}
      size={size}
      withMargin={withMargin}
    >
      {text}
      {!!children && (
        <>
          {' '}
          <Inline>{children}</Inline>
        </>
      )}
    </BodyText>
  )
}

export default Text
