import { FC } from 'react'
import { colors } from '@/styles/colors'
import styled from 'styled-components'
import { fontStyles } from '@/styles/fonts'
import { transitions } from '@/styles/shared'
import DownArrowIcon from '@/svg/DownArrowIcon'
import { motion } from 'framer-motion'

type Props = {
  onClick?: () => void
  text: string
  open: boolean
}

const Button = styled.button`
  ${fontStyles.button.md}

  color: ${colors.brand};
  border: 1rem solid ${colors.brand};
  border-radius: 8rem 0;
  padding: 10.5rem 15rem 11.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8rem;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  ${transitions.hover}

  path {
    fill: ${colors.brand};
  }

  &:hover {
    color: ${colors.gray40};
    border-color: ${colors.gray40};

    path {
      fill: ${colors.gray40};
    }
  }
`

const Arrow = styled(motion.div)`
  display: flex;
  align-items: center;
  height: 8rem;
`

const ToggleButton: FC<Props> = ({ onClick, open, text }) => {
  return (
    <Button onClick={onClick}>
      {text}
      <Arrow
        transition={{ type: undefined, duration: 0.3 }}
        animate={{
          rotate: open ? 180 : 0,
        }}
      >
        <DownArrowIcon
          variant="white"
          width={12}
        />
      </Arrow>
    </Button>
  )
}

export default ToggleButton
