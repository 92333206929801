export default {
  privacyCheck: 'Je bent nog niet akkoord gegaan met het privacy statement',
  checkbox: 'Deze optie is verplicht',
  choice: 'Maak een keuze',
  "double": 'De ingevulde waarde moet hetzelfde zijn als:',
  email: 'Vul een volledig e-mailadres in',
  email_double: 'Herhaal hier je e-mailadres',
  customerName: 'Vul een naam van je huishouden in waarmee we je / jullie mogen aanspreken',
  name: 'Vul een naam in waarmee we je mogen aanspreken',
  password: 'Vul hier je wachtwoord in',
  radio: 'Maak een keuze',
  bamDate: 'Dit is geen geldige datum',
  required: 'Dit veld is verplicht'
};