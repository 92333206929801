var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.lightBeige,
  width: 12,
  height: 12
};
var Login = function Login(_ref) {
  var width = _ref.width,
    height = _ref.height,
    color = _ref.color;
  return __jsx("svg", {
    style: {
      width: "".concat(String(width), "px"),
      height: "".concat(String(height), "px")
    },
    viewBox: "0 0 12 12",
    version: "1.1",
    "data-id": "icons_Login_svg"
  }, __jsx("title", {
    "data-id": "icons_Login_title"
  }, "Login icoon"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_Login_g"
  }, __jsx("g", {
    transform: "translate(-233.000000, -21.000000)",
    fill: color,
    "data-id": "icons_Login_g_3"
  }, __jsx("g", {
    transform: "translate(233.000000, 21.000000)",
    "data-id": "icons_Login_g_4"
  }, __jsx("g", {
    "data-id": "icons_Login_g_5"
  }, __jsx("path", {
    d: "M4.52357145,6.5406911 C3.61388648,5.94016133 3,4.80181145 3,3.49635796 C3,1.56537278 4.34314575,0 6,0 C7.65685425,0 9,1.56537278 9,3.49635796 C9,4.80158005 8.38633113,5.93975777 7.47691226,6.54037171 C7.49205194,6.6158104 7.5,6.69399679 7.5,6.77419355 C7.5,7.41555648 6.97743988,7.93548387 6.34870148,7.93548387 L5.65129852,7.93548387 C5.01545391,7.93548387 4.5,7.42002795 4.5,6.77419355 C4.5,6.69421337 4.50812631,6.61612163 4.52357145,6.5406911 Z M3.45769897,6.77419355 C3.74910206,8.10985333 4.77685537,9.09677419 6,9.09677419 C7.22314463,9.09677419 8.25089794,8.10985333 8.54230103,6.77419355 L9.39617872,6.77419355 C10.8342295,6.77419355 12,7.94696652 12,9.38709677 L12,12 L0,12 L0,9.38709677 C0,7.94403017 1.17245954,6.77419355 2.60382128,6.77419355 L3.45769897,6.77419355 Z",
    "data-id": "icons_Login_path"
  }))))));
};
Login.defaultProps = defaultProps;
export default Login;