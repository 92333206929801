import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/**

  PLEASE SEE README.md FOR MORE INFORMATION ON THE REDUX SETUP AND LIBRARIES USED IN THIS APPLICATION.

*/
import request from '../services/request';
import { normalize, denormalize } from 'normalizr';
import get from 'lodash.get';
import { getAllRequestsForProject } from '@/processes/apiDataHelpers';
var __DEV__ = process.env.NODE_ENV === 'development';

// state def

var defaultState = {
  globalConfig: {},
  endpointConfig: {},
  requests: {},
  entities: {}
};

// actions

// reducer

export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case 'CONFIGURE_API_DATA':
      return _objectSpread(_objectSpread({}, state), action.payload);
    case 'FETCH_API_DATA':
      return _objectSpread(_objectSpread({}, state), {}, {
        requests: _objectSpread(_objectSpread({}, state.requests), {}, _defineProperty({}, action.payload.requestKey, _objectSpread(_objectSpread({}, state.requests[action.payload.requestKey]), {}, {
          networkStatus: 'loading',
          lastCall: Date.now()
        })))
      });
    case 'API_DATA_SUCCESS':
      return _objectSpread(_objectSpread({}, state), {}, {
        requests: _objectSpread(_objectSpread({}, state.requests), {}, _defineProperty({}, action.payload.requestKey, {
          networkStatus: 'success',
          lastCall: state.requests[action.payload.requestKey] ? state.requests[action.payload.requestKey].lastCall : undefined,
          result: action.payload.normalizedData ? action.payload.normalizedData.result : undefined,
          response: action.payload.response
        })),
        entities: _objectSpread({}, action.payload.normalizedData ? addEntities(state.entities, action.payload.normalizedData.entities) : state.entities)
      });
    case 'API_DATA_FAIL':
      return _objectSpread(_objectSpread({}, state), {}, {
        requests: _objectSpread(_objectSpread({}, state.requests), {}, _defineProperty({}, action.payload.requestKey, {
          networkStatus: 'failed',
          lastCall: state.requests[action.payload.requestKey] && state.requests[action.payload.requestKey].lastCall,
          response: action.payload.response,
          errorBody: action.payload.errorBody
        }))
      });
    case 'INVALIDATE_API_DATA_REQUEST':
      {
        var _request2 = state.requests[action.payload.requestKey];
        return _request2 ? _objectSpread(_objectSpread({}, state), {}, {
          requests: _objectSpread(_objectSpread({}, state.requests), {}, _defineProperty({}, action.payload.requestKey, _objectSpread(_objectSpread({}, _request2), {}, {
            networkStatus: 'ready'
          })))
        }) : state;
      }
    case 'CLEAR_ENTITIES_AND_REQUESTS':
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          requests: {},
          entities: {}
        });
      }
    default:
      return state;
  }
});

// merges newEntities into entities
var addEntities = function addEntities(entities, newEntities) {
  return Object.keys(newEntities).reduce(function (result, entityType) {
    return _objectSpread(_objectSpread({}, result), {}, _defineProperty({}, entityType, _objectSpread(_objectSpread({}, entities[entityType] || {}), newEntities[entityType])));
  }, _objectSpread({}, entities));
};
var formatUrl = function formatUrl(url, params, queryString) {
  return !params ? url : url.replace(/:[a-zA-Z]+/g, function (match) {
    return params ? String(params[match.substr(1)]) || '' : '';
  });
};
var getRequestKey = function getRequestKey(endpointKey) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return endpointKey + '/' + Object.keys(params).sort().map(function (param) {
    return param + '=' + params[param];
  }).join('&');
};

// action creators

export var configureApiData = function configureApiData(globalConfig, endpointConfig) {
  return {
    type: 'CONFIGURE_API_DATA',
    payload: {
      globalConfig: globalConfig,
      endpointConfig: endpointConfig
    }
  };
};
var apiDataSuccess = function apiDataSuccess(requestKey, endpointConfig, response, body) {
  return {
    type: 'API_DATA_SUCCESS',
    payload: {
      requestKey: requestKey,
      response: response,
      normalizedData: typeof endpointConfig.transformResponseBody === 'function' ? endpointConfig.transformResponseBody(body) : endpointConfig.responseSchema ? normalize(body, endpointConfig.responseSchema) : undefined
    }
  };
};
var apiDataFail = function apiDataFail(requestKey, response, errorBody) {
  return {
    type: 'API_DATA_FAIL',
    payload: {
      requestKey: requestKey,
      response: response,
      errorBody: errorBody
    }
  };
};
export var performApiRequest = function performApiRequest(endpointKey, params, body, options) {
  return function (dispatch, getState) {
    var state = getState();
    var config = state.apiData.endpointConfig[endpointKey];
    var globalConfig = state.apiData.globalConfig;
    if (!config) {
      if (__DEV__) {
        console.error("apiData.performApiRequest: no config with key ".concat(endpointKey, " found!"));
      }
      return;
    }
    var apiDataRequest = getApiDataRequest(state.apiData, endpointKey, params);
    if (apiDataRequest && (apiDataRequest.networkStatus === 'loading' || config.method === 'GET' && apiDataRequest.networkStatus === 'success' && (!options || !options.cache || options.cache === 'enabled'))) {
      // don't re-trigger calls when already loading,
      // don't re-trigger succeeded GET calls
      // don't re-trigger for which cache setting is enabled
      return;
    }
    var requestKey = getRequestKey(endpointKey, params || {});
    dispatch({
      type: 'FETCH_API_DATA',
      payload: {
        requestKey: requestKey
      }
    });
    var defaultRequestProperties = {
      body: body,
      headers: {},
      method: config.method
    };
    var requestProperties = typeof globalConfig.setRequestProperties === 'function' ? globalConfig.setRequestProperties(defaultRequestProperties, state) : defaultRequestProperties;
    requestProperties.headers = typeof globalConfig.setHeaders === 'function' ? globalConfig.setHeaders(defaultRequestProperties.headers, state) : defaultRequestProperties.headers;
    var onError = function onError(endpointKey, response, body) {
      if (typeof config.handleErrorResponse === 'function' && config.handleErrorResponse(endpointKey, response, body, dispatch) === false) {
        return;
      }
      if (typeof globalConfig.handleErrorResponse === 'function') {
        globalConfig.handleErrorResponse(endpointKey, response, body, dispatch);
      }
    };
    var queryString = window.location.search;
    return request("".concat(formatUrl(config.url, params)).concat(config.includeQueryString ? queryString : ''), requestProperties).then(function (response) {
      if (response.response.ok) {
        // Here we re-add the request parameters to the response we get.
        // Otherwise the backend would be forced to always send the same
        // key-value pairs in their response that we already know because
        // we requested them in the first place.

        // We only do this to api endpoints that are configured as a single object.
        // If an api endpoint is configured to retrieve multiple instances, we do not
        // add params for now.
        var _params2 = requestKey.split(/\/(.+)/);
        var _requestParams;
        if (_params2.length) {
          _requestParams = (_params2[1] || '').split('&').reduce(function (acc, curr) {
            var keyValue = curr.split('=');
            acc[keyValue[0]] = keyValue[1];
            return acc;
          }, {});
        }

        // The response from fetch contains stuff like buffer and raw data associated with the request.
        // We don't need that clogging up our preloaded data object or Redux store so we delete that.
        delete response.response.body;
        // $FlowFixMe
        delete response.response._raw;
        dispatch(apiDataSuccess(requestKey, config, response.response, config.responseSchema && config.responseSchema.constructor === Array ? response.body : Object.assign({}, response.body, _requestParams)));
      } else {
        dispatch(apiDataFail(requestKey, response.response, response.body));
        onError(endpointKey, response.response, response.body);
      }
    }, function (error) {
      dispatch(apiDataFail(requestKey, undefined, error));
      onError(endpointKey, undefined, error);
    });
  };
};

// Invalidates the result of a request, setting it's status back to 'ready'. Use for example after a POST, to invalidate
// a GET list request, which might need to include the newly created entity.
export var invalidateApiDataRequest = function invalidateApiDataRequest(endpointKey, params) {
  return {
    type: 'INVALIDATE_API_DATA_REQUEST',
    payload: {
      requestKey: getRequestKey(endpointKey, params)
    }
  };
};

// selectors

export var getApiDataRequest = function getApiDataRequest(apiDataState, endpointKey, params) {
  return get(apiDataState, "requests[".concat(getRequestKey(endpointKey, params), "]"));
};

/**
 * This fixes the issue that we can't retrieve requests from the ApiDataState if we don't know all the params.
 * We should be able to remove this after refactoring LayoutWishList.
 */
export var getApiDataRequestsForProject = getAllRequestsForProject;

// Get the de-normalized result data of an endpoint, or undefined if not (yet) available
export var getResultData = function getResultData(apiDataState, endpointKey, params) {
  var config = apiDataState.endpointConfig[endpointKey];
  var request = getApiDataRequest(apiDataState, endpointKey, params);
  if (!config) {
    if (__DEV__) {
      console.warn("apiData.getResult: configuration of endpoint ".concat(endpointKey, " not found."));
    }
    return;
  }
  if (!request || !request.result) {
    return;
  }
  return request.result && denormalize(request.result, config.responseSchema, apiDataState.entities);
};
export var getEntity = function getEntity(apiDataState, schema, id) {
  var entity = apiDataState.entities[schema.key] && apiDataState.entities[schema.key][id];
  return entity && denormalize(id, schema, apiDataState.entities);
};
export var clearCache = function clearCache() {
  return {
    type: 'CLEAR_ENTITIES_AND_REQUESTS',
    payload: {}
  };
};