import { ReactNode } from 'react'
import styled from 'styled-components'
import { colors, RGBAColors } from '@/styles/colors'
import { positions } from '@/styles/positions'
import { fontStyles } from '@/styles/fonts'
import InformationIcon from '@/atoms/InformationIcon'
import shadows from '@/styles/shadows'

type Variant = 'primary' | 'disabled'

type Props = {
  content?: string
  variant?: Variant
  children?: ReactNode
  shadow?: boolean
}

const Container = styled.div<{ variant: Variant; shadow: boolean }>`
  ${({ shadow }) => shadow && shadows.default}
  background-color: ${({ variant }) => (variant === 'disabled' ? colors.gray10 : colors.brand)};
  padding: 12rem 20rem;
  position: relative;
  border-radius: 8rem 0;
`

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  gap: 12rem;
`

const WrapperOpacityBlock1 = styled.div<{ variant: Variant }>`
  ${positions.absolute.topLeft}
  background-color: ${({ variant }) =>
    variant === 'disabled' ? RGBAColors.gray12 : RGBAColors.white12};
  height: 100%;
  width: 100%;
  clip-path: polygon(0 50%, 0% 100%, 40rem 100%);
`

const WrapperOpacityBlock2 = styled.div<{ variant: Variant }>`
  ${positions.absolute.topLeft}
  background-color: ${({ variant }) =>
    variant === 'disabled' ? RGBAColors.gray12 : RGBAColors.white12};
  height: 100%;
  width: 100%;
  clip-path: polygon(0% 0%, 80rem 0%, 0% 100%);
  border-radius: 8rem 0 0;
`

const Text = styled.span<{ variant: Variant }>`
  ${fontStyles.body.md}
  color: ${({ variant }) => (variant === 'disabled' ? colors.gray40 : colors.white)};
`

const Banner = ({ children, content, variant = 'primary', shadow = true }: Props) => {
  if (!content && !children) {
    return null
  }

  return (
    <Container
      variant={variant}
      shadow={shadow}
    >
      <WrapperOpacityBlock1 variant={variant} />
      <WrapperOpacityBlock2 variant={variant} />
      <ContentWrapper>
        <InformationIcon />
        <Text variant={variant}>{children ?? content}</Text>
      </ContentWrapper>
    </Container>
  )
}

export default Banner
