import flatMap from 'lodash.flatmap';
export default (function (form, values) {
  if (!values) return {};
  var excludeFields = flatMap(form, function (formCat) {
    return formCat.options;
  }).filter(function (option) {
    return option.excludeFromSubmit;
  }).map(function (option) {
    return option.name;
  });
  excludeFields.forEach(function (element) {
    if (values && values.hasOwnProperty(element)) {
      delete values[element];
    }
  });
  return values;
});