import { ErrorBlockProps } from '@/components/ErrorBlock'
import { Match } from '@/types/match'

export const apiErrorMessages = {
  401: 'U bent niet ingelogd. U wordt naar het inlogscherm geleid.',
  403: 'U heeft geen toegang tot deze pagina.',
  500: 'Er is iets mis gegaan met de communicatie met de server.',
  default: 'Er is iets mis gegaan.',
}

const reasons: Record<number, string> = {
  404: 'deze pagina is niet gevonden',
  500: 'blijkbaar is er iets fout gegaan',
  4044: 'dit project is niet gevonden',
}

export const getErrorBlockProps = (
  match?: Match,
  endpoint?: string,
  statusCode = 0
): Omit<ErrorBlockProps, 'fullscreen'> => {
  const projectSlug = match?.params?.projectSlug
  const env = match?.params?.env ?? 'open'

  let button
  if (match?.params) {
    button = {
      label: env === 'open' ? 'Naar de homepage' : 'Naar het dashboard',
      link: [projectSlug, env].filter((slug) => !!slug).join('/'),
    }
  }

  let displayStatusCode = statusCode
  const reasonText = reasons[statusCode] ?? reasons[500]

  // 4044 is a special case: to handle unexpected response from getSettings, which means the project does not exist
  if (statusCode === 4044) {
    displayStatusCode = 404
    button = {
      label: 'Naar de Homestudios portal',
      link: '',
    }
  }

  return {
    button,
    code: endpoint ? `${displayStatusCode} (${endpoint})` : displayStatusCode.toString(),
    description: {
      content: `Onze excuses, ${reasonText}. Meld het probleem aan ons via [href type="link" href="mailto:info@homestudios.nl?subject=Foutpagina (${statusCode}) op Mijn Homestudios voor ${projectSlug}&body=Homestudios gaf niet thuis.%0D%0AURL: ${
        window.location.href
      }%0D%0ATimestamp: ${+new Date()}"]info@homestudios.nl[/href], dan zorgen wij dat het probleem zo snel mogelijk wordt verholpen.`,
    },
    title: 'Homestudios.nl geeft even niet thuis',
  }
}
