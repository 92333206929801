import React, { Component } from 'react'
import styled from 'styled-components'
import ReactPlayer, { ReactPlayerProps } from 'react-player'
import { devLog } from '@/services/devLog'
import ContentLoader from '../ui/ContentLoader'
import { colorPalette } from '../../../styles/styleGuide.js'

type Props = {
  type: 'youtube' | 'vimeo'
  id?: string
  aspectRatio?: number
}

type State = {
  loading: boolean
  ready: boolean
  vimeoThumbnailSrc?: string
  hovered: boolean
}

type StylePropHovered = { hovered: boolean }
type StylePropBackground = { background?: string }
type StylePropAspectRatio = { aspectRatio: number }

const Container = styled.div<StylePropAspectRatio>`
  aspect-ratio: ${({ aspectRatio }) => aspectRatio};
  overflow: hidden;
  border-radius: 8rem 0;
  position: relative;
`

const PlaceholderContainer = styled.div`
  position: relative;
  height: 100%;
  background-color: ${colorPalette.lightGrey};
`

const placeholderBackgroundStyles = ({
  background,
  hovered,
}: StylePropBackground & StylePropHovered) => {
  let result = ''
  if (background) {
    result += `background-image: url(${background});`
  }
  if (hovered) {
    result += `opacity: 0.75;`
  }
  return result
}

const PlaceholderBackground = styled.div<StylePropBackground & StylePropHovered>`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-size: cover;
  background-position: center;
  transition: opacity 300ms ease-in-out;
  opacity: 1;

  ${placeholderBackgroundStyles};
`

const PlaceholderButtonShadowStyles = ({ hovered }: StylePropHovered) =>
  hovered ? `opacity: 1;` : ''

const PlaceholderButtonShadow = styled.div<StylePropHovered>`
  width: 66rem;
  height: 66rem;
  top: 50%;
  left: 50%;
  margin: -35rem 0 0 -35rem;
  position: absolute;
  border-radius: 8rem 0;
  transition: opacity 300ms ease-in-out;
  z-index: 2;
  opacity: 0.5;
  box-shadow: 0 2rem 10rem 0 rgba(0 0 0 / 0.5);

  ${PlaceholderButtonShadowStyles};
`

const PlaceholderButtonOuter = styled.div`
  box-sizing: border-box;
  width: 66rem;
  height: 66rem;
  top: 50%;
  left: 50%;
  margin: -35rem 0 0 -35rem;
  position: absolute;
  background-color: ${colorPalette.orange};
  border-radius: 8rem 0;
  padding: 20rem 18rem 20rem 22rem;
  transition: background-color 300ms ease-in-out;
  z-index: 3;
  cursor: pointer;
`

const PlaceholderButtonInner = styled.div`
  box-shadow: inset 0 0 0 1rem rgba(0 0 0 / 0.2);
  box-sizing: border-box;
  width: 26rem;
  height: 26rem;
  border-style: solid;
  border-width: 13rem 0 13rem 26rem;
  border-color: transparent transparent transparent ${colorPalette.white};
`

const ReactPlayerContainer = styled.div`
  position: relative;
  height: 100%;
`

const StyledContentLoader = styled(ContentLoader)`
  position: absolute;
  width: 100%;
  height: 100%;
`

const StyledReactPlayer = styled(
  ReactPlayer as unknown as (props: ReactPlayerProps) => JSX.Element
)`
  position: absolute;
  width: 100%;
  height: 100%;
`

type PlaceHolderProps = {
  src?: string
  onClick: () => void
  setHovered: (hovered: boolean) => void
  hovered: boolean
}

const Placeholder = ({ src, onClick, setHovered, hovered }: PlaceHolderProps) => {
  return (
    <PlaceholderContainer>
      {!!src && (
        <PlaceholderBackground
          background={src}
          hovered={hovered}
        />
      )}
      <PlaceholderButtonShadow hovered={hovered} />
      <PlaceholderButtonOuter
        onMouseEnter={() => {
          setHovered(true)
        }}
        onMouseLeave={() => {
          setHovered(false)
        }}
        onClick={onClick}
      >
        <PlaceholderButtonInner />
      </PlaceholderButtonOuter>
    </PlaceholderContainer>
  )
}

class Video extends Component<Props, State> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    aspectRatio: 16 / 9,
  }

  constructor(props: Props) {
    super(props)

    this.state = {
      loading: false,
      ready: false,
      vimeoThumbnailSrc: undefined,
      hovered: false,
    }
  }

  componentDidMount() {
    this.fetchVimeoThumbnail()
  }

  getUrlAndSrc = () => {
    const { id, type } = this.props
    if (!id || !type) {
      return {}
    }
    if (type === 'youtube') {
      return {
        url: `https://www.youtube.com/watch?v=${id}`,
        src: `https://img.youtube.com/vi/${id}/0.jpg`,
      }
    }
    const { vimeoThumbnailSrc } = this.state
    return {
      url: `https://vimeo.com/${id}`,
      src: vimeoThumbnailSrc,
    }
  }

  fetchVimeoThumbnail = () => {
    const { id, type } = this.props
    if (!id || type !== 'vimeo') {
      return
    }
    try {
      fetch(`https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/${id}`)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            this.setState({ vimeoThumbnailSrc: data.thumbnail_url })
          }
        })
    } catch (e) {
      devLog(e)
    }
  }

  render() {
    const { id, type, aspectRatio } = this.props

    if (!id || !type) {
      return null
    }

    const { loading, ready, hovered } = this.state

    const { url, src } = this.getUrlAndSrc()

    if (!url) {
      return null
    }

    // linter does not understand defaultProps syntax
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const ratio = aspectRatio!

    return (
      <>
        <br />
        <Container aspectRatio={ratio}>
          {!ready && !loading ? (
            <Placeholder
              onClick={() => {
                this.setState({ loading: true })
              }}
              src={src}
              hovered={hovered}
              setHovered={(hovered) => {
                this.setState({ hovered })
              }}
            />
          ) : (
            <ReactPlayerContainer>
              <StyledReactPlayer
                url={url}
                width="100%"
                height="100%"
                playing
                onReady={() => {
                  this.setState({ loading: false, ready: true })
                }}
                config={{ youtube: { playerVars: { controls: 1 } } }}
              />
              {loading && <StyledContentLoader fit />}
            </ReactPlayerContainer>
          )}
        </Container>
      </>
    )
  }
}

export default Video
