import styled from 'styled-components'

const SvgAtr = styled.svg.attrs({
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
})``

const Svg = styled(SvgAtr)``

export default Svg
