import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { BASE_URL } from './apiUrls';
import { activationSchema, articleSchema, blockSchema, feedbackSchema, footerPageSchema, formSchema, houseTypeSchema, hotspotOptionsSchema, notificationsSchema, pageSchema, profileSchema, planningItemSchema, projectShowcaseSchema, settingsSchema, smallWishListSchema, wishListSchema, wishListModalCategorySchema, wishListModalProductOptionSchema, wishListModalSubCategorySchema, productOptionOrderSchema, verificationStatusSchema, verificationTokenSchema, productOptionRequestOrderSchema } from '../entities/relationSchema';
import { showApiError } from '../redux/globalApiError';
import { apiErrorMessages } from '../constants/errorMessages';
export var globalConfig = {
  errorMessages: apiErrorMessages,
  setRequestProperties: function setRequestProperties(defaultProperties, state) {
    return _objectSpread(_objectSpread({}, defaultProperties), {}, {
      credentials: 'include'
    });
  },
  handleErrorResponse: function handleErrorResponse(endpointKey, response, body, dispatch) {
    if (endpointKey !== 'loginUser') {
      dispatch(showApiError(endpointKey, response, body));
    }
  }
};
export var endpointConfig = {
  getSettings: {
    url: BASE_URL + '/settings/:projectSlug/:env/',
    method: 'GET',
    responseSchema: settingsSchema,
    includeQueryString: true
  },
  getPage: {
    url: BASE_URL + "/page/:pagePath/",
    method: 'GET',
    responseSchema: pageSchema,
    includeQueryString: true
  },
  getBlock: {
    url: BASE_URL + '/block/:projectSlug/:env/:blockId/',
    method: 'GET',
    responseSchema: blockSchema
  },
  getArticlePage: {
    url: BASE_URL + '/block/:projectSlug/:env/:blockId/:pageNumber/',
    method: 'GET',
    responseSchema: blockSchema
  },
  getArticle: {
    url: BASE_URL + '/article/:projectSlug/:env/:articleSlug/',
    method: 'GET',
    responseSchema: articleSchema
  },
  getHouseTypes: {
    url: BASE_URL + '/housetype/:projectSlug/',
    method: 'GET',
    responseSchema: [houseTypeSchema]
  },
  getHouseType: {
    url: BASE_URL + '/housetype/:projectSlug/:env/:houseTypeSlug/',
    method: 'GET',
    responseSchema: houseTypeSchema
  },
  getForm: {
    url: BASE_URL + '/dataform/:projectSlug/:formId/',
    method: 'GET',
    responseSchema: formSchema
  },
  postForm: {
    url: BASE_URL + '/dataform/:projectSlug/:formId/',
    method: 'POST',
    responseSchema: formSchema
  },
  getWishLists: {
    url: BASE_URL + '/wishlist/:projectSlug/closed/',
    method: 'GET',
    responseSchema: [smallWishListSchema]
  },
  getWishList: {
    url: BASE_URL + '/wishlist/:projectSlug/closed/:wishListId/',
    method: 'GET',
    responseSchema: wishListSchema
  },
  postWishList: {
    url: BASE_URL + '/wishlist/:projectSlug/closed/',
    method: 'POST',
    responseSchema: wishListSchema
  },
  patchWishList: {
    url: BASE_URL + '/wishlist/:projectSlug/closed/:wishListId/',
    method: 'PATCH',
    responseSchema: wishListSchema
  },
  deleteWishList: {
    url: BASE_URL + '/wishlist/:projectSlug/closed/:wishListId/',
    method: 'DELETE'
  },
  duplicateWishList: {
    url: BASE_URL + '/wishlist/:projectSlug/closed/:wishListId/duplicate/',
    method: 'POST',
    responseSchema: wishListSchema
  },
  getWishListModalCategory: {
    url: BASE_URL + '/wishlist/:projectSlug/closed/modal/category/:modalId/',
    method: 'GET',
    responseSchema: wishListModalCategorySchema
  },
  getWishListModalSubCategory: {
    url: BASE_URL + '/wishlist/:projectSlug/closed/modal/subcategory/:modalId/',
    method: 'GET',
    responseSchema: wishListModalSubCategorySchema
  },
  getWishListModalProductOption: {
    url: BASE_URL + '/wishlist/:projectSlug/closed/modal/option/:modalId/',
    method: 'GET',
    responseSchema: wishListModalProductOptionSchema
  },
  postUserWish: {
    url: BASE_URL + '/userwish/:projectSlug/closed/',
    method: 'POST',
    responseSchema: wishListSchema
  },
  deleteUserWish: {
    url: BASE_URL + '/userwish/:projectSlug/closed/:userWishId/',
    method: 'DELETE'
  },
  patchUserWish: {
    url: BASE_URL + '/userwish/:projectSlug/closed/:userWishId/',
    method: 'PATCH'
  },
  postFeedback: {
    url: BASE_URL + '/feedback/:projectSlug/closed/',
    method: 'POST',
    responseSchema: feedbackSchema
  },
  patchFeedback: {
    url: BASE_URL + '/feedback/:projectSlug/closed/:feedbackId/',
    method: 'PATCH',
    responseSchema: feedbackSchema
  },
  postAction: {
    url: BASE_URL + '/todo/:projectSlug/closed/',
    method: 'POST',
    responseSchema: planningItemSchema
  },
  patchAction: {
    url: BASE_URL + '/todo/:projectSlug/closed/:actionId/',
    method: 'PATCH',
    responseSchema: planningItemSchema
  },
  deleteAction: {
    url: BASE_URL + '/todo/:projectSlug/closed/:actionId/',
    method: 'DELETE',
    responseSchema: planningItemSchema
  },
  loginUser: {
    url: BASE_URL + '/login/',
    method: 'POST'
  },
  logoutUser: {
    url: BASE_URL + '/logout/',
    method: 'GET'
  },
  signupUser: {
    url: BASE_URL + '/:projectSlug/register/',
    method: 'POST'
  },
  requestPassword: {
    url: BASE_URL + '/:projectSlug/password/request/',
    method: 'POST'
  },
  resetPassword: {
    url: BASE_URL + '/:projectSlug/password/reset/',
    method: 'POST'
  },
  getProfile: {
    url: BASE_URL + '/profile/:projectSlug/closed/',
    method: 'GET',
    responseSchema: profileSchema
  },
  patchProfile: {
    url: BASE_URL + '/profile/:projectSlug/closed/',
    method: 'PATCH',
    responseSchema: profileSchema
  },
  postUserProfilePicture: {
    url: BASE_URL + '/profile/:projectSlug/closed/image/user/',
    method: 'POST'
  },
  postCustomerProfilePicture: {
    url: BASE_URL + '/profile/:projectSlug/closed/image/customer/',
    method: 'POST'
  },
  getFooterPage: {
    url: BASE_URL + '/footer/:projectSlug/:env/:footerPageSlug/',
    method: 'GET',
    responseSchema: footerPageSchema
  },
  getNotifications: {
    url: BASE_URL + '/profile/:projectSlug/closed/notifications/',
    method: 'GET',
    responseSchema: notificationsSchema
  },
  postActivationCode: {
    url: BASE_URL + '/house/:projectSlug/closed/activate/',
    method: 'POST',
    responseSchema: activationSchema
  },
  getHotspotOptions: {
    url: BASE_URL + '/ecw/hotspot/:hotspotId/',
    method: 'GET',
    responseSchema: hotspotOptionsSchema
  },
  getProjectShowcase: {
    url: BASE_URL + '/project-showcase/:projectSlug/',
    method: 'GET',
    responseSchema: projectShowcaseSchema
  },
  postRequestOption: {
    url: BASE_URL + '/order/:projectSlug/closed/',
    method: 'POST',
    responseSchema: productOptionRequestOrderSchema
  },
  postRequestOptionFile: {
    url: BASE_URL + '/order/:projectSlug/closed/:orderId/upload',
    method: 'POST'
  },
  getOptionOrder: {
    url: BASE_URL + '/order/:projectSlug/closed/:orderId',
    method: 'GET',
    responseSchema: productOptionOrderSchema
  },
  postOptionOrder: {
    url: BASE_URL + '/order/:projectSlug/closed/:orderId/status',
    method: 'POST',
    responseSchema: productOptionOrderSchema
  },
  getVerificationStatus: {
    url: BASE_URL + '/verification/status',
    method: 'GET',
    responseSchema: verificationStatusSchema
  },
  getVerificationToken: {
    url: BASE_URL + '/verification/token',
    method: 'GET',
    responseSchema: verificationTokenSchema
  },
  postVerificationToken: {
    url: BASE_URL + '/verification/token/',
    method: 'POST'
  },
  postModalDismiss: {
    url: BASE_URL + '/modal/:projectSlug/:env/:modalId/dismiss/',
    method: 'POST'
  },
  postOpenFaq: {
    url: BASE_URL + '/analytics/faq/',
    method: 'POST'
  },
  postFAQFeedback: {
    url: BASE_URL + '/analytics/faq/',
    method: 'POST'
  }
};