import { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { colors } from '@/styles/colors'
import { fontStyles } from '@/styles/fonts'
import { IconProps } from '@/types/svgTypes'
import { SIZE_MAP } from '@/styles/spacing'

type Variant = 'brand' | 'white' | 'gray40' | 'gray30' | 'gray25'

type Size = 'xs' | 'sm' | 'md' | 'lg'

type Props = {
  level?: 1 | 2 | 3 | 4 | 5 | 6
  size?: Size
  variant?: Variant
  text?: string
  children?: ReactNode
  icon?: FC<IconProps>
  hideOverflow?: boolean
  onClick?: () => void
  withMargin?: boolean
}

const TitleText = styled.h1<{ size: Size; variant: Variant; withMargin: boolean }>`
  margin: ${({ withMargin }) => (withMargin ? `${SIZE_MAP.md}rem 0` : 0)};
  ${({ size }) => fontStyles.title[size]}
  color: ${({ variant }) => colors[variant]};
  display: flex;
  gap: 12rem;
  align-items: center;
`

const TextSpan = styled.span<{ hideOverflow: boolean }>`
  ${({ hideOverflow }) =>
    hideOverflow &&
    `
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
  `}
`

const ICON_HEIGHT_MAP: Record<Size, number> = {
  xs: 14,
  sm: 16,
  md: 20,
  lg: 26,
}

const Title = ({
  level = 1,
  size = 'lg',
  variant = 'gray40',
  text,
  icon,
  hideOverflow = false,
  onClick,
  children,
  withMargin = false,
}: Props) => {
  const Icon = icon

  return (
    <TitleText
      variant={variant}
      size={size}
      as={`h${level}`}
      onClick={onClick}
      withMargin={withMargin}
    >
      {Icon && (
        <Icon
          variant="brand"
          height={ICON_HEIGHT_MAP[size]}
        />
      )}
      <TextSpan hideOverflow={hideOverflow}>{text ?? children}</TextSpan>
    </TitleText>
  )
}
export default Title
