import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { shadows, colorPalette, textStyles } from '../styles/styleGuide';
import { toBreakpoint } from '../styles/breakpoints';
import ButtonSecondary from './shared/buttons/ButtonSecondary';
import { GridWrapper, GridItem } from '../styles/gridElements';
import BBCode from './shared/richText/BBCode';
import { Match } from '@/types/match';
var ErrorBlock = function ErrorBlock(_ref) {
  var fullscreen = _ref.fullscreen,
    title = _ref.title,
    code = _ref.code,
    description = _ref.description,
    button = _ref.button;
  return __jsx(Container, {
    fullscreen: fullscreen,
    "data-e2e-errorblock": code,
    "data-id": "components_ErrorBlock_Container"
  }, __jsx(GridWrapper, {
    "data-id": "components_ErrorBlock_GridWrapper"
  }, __jsx(GridItem, {
    "data-id": "components_ErrorBlock_GridItem"
  }, __jsx(Block, {
    "data-id": "components_ErrorBlock_Block"
  }, __jsx(Title, {
    "data-id": "components_ErrorBlock_Title"
  }, title), __jsx(Code, {
    "data-id": "components_ErrorBlock_Code"
  }, "Statuscode ", code), __jsx(Description, {
    "data-id": "components_ErrorBlock_Description"
  }, __jsx(BBCode, {
    content: description,
    "data-id": "components_ErrorBlock_BBCode"
  })), !!button && __jsx(ButtonSecondary, {
    type: "internalLink",
    label: button.label,
    route: '/' + button.link,
    "data-id": "components_ErrorBlock_ButtonSecondary"
  })))));
};
export default ErrorBlock;
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  box-sizing: border-box;\n  background-color: ", ";\n  padding: 80rem 130rem 80rem 130rem;\n  flex-grow: 1;\n  min-height: ", ";\n\n  ", " ", ";\n"])), colorPalette.background, function (props) {
  return props.fullscreen ? '100vh' : '';
}, toBreakpoint.lg(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    padding: 80rem 70rem 60rem 70rem;\n  "]))), toBreakpoint.md(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    padding: 40rem 10rem 40rem 10rem;\n  "]))));
var Block = styled(GridItem)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", ";\n  background-color: white;\n  padding: 80rem 100rem 80rem 100rem;\n\n  ", " ", "\n\n  > a {\n    margin-top: 30rem;\n  }\n"])), shadows.boxPrimary, toBreakpoint.lg(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    padding: 60rem 70rem 60rem 70rem;\n  "]))), toBreakpoint.md(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    padding: 40rem 20rem 40rem 20rem;\n  "]))));
var Title = styled.h2(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  margin-bottom: 10rem;\n  margin-top: 0;\n"])), textStyles.pageTitle, colorPalette.headerGrey);
var Code = styled.p(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  ", "\n  color: ", ";\n  margin-top: 0;\n  font-weight: normal;\n"])), textStyles.subTitle, colorPalette.textGrey);
var Description = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  ", "\n  color: ", ";\n  font-weight: normal;\n  max-width: 750rem;\n  margin-bottom: 0;\n"])), textStyles.title, colorPalette.textGrey);