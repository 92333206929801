import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;
var __jsx = React.createElement;
import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { colorPalette } from '../../../styles/styleGuide';
var defaultProps = {
  size: 'small',
  monochrome: false
};
var Spinner = function Spinner(_ref) {
  var size = _ref.size,
    color = _ref.color,
    monochrome = _ref.monochrome;
  return __jsx(Container, {
    size: size,
    "data-id": "ui_Spinner_Container"
  }, __jsx(SpinnerContainer, {
    height: "100%",
    width: "100%",
    viewBox: "0 0 66 66",
    "data-id": "ui_Spinner_SpinnerContainer"
  }, __jsx(Circle, {
    color: color,
    monochrome: monochrome,
    fill: "none",
    strokeWidth: "6",
    strokeLinecap: "round",
    cx: "33",
    cy: "33",
    r: "30",
    "data-id": "ui_Spinner_Circle"
  })));
};
Spinner.defaultProps = defaultProps;
export default Spinner;
var OFFSET = 187;
var DURATION = 1.4;
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: ", ";\n  left: ", ";\n  top: ", ";\n  width: ", ";\n  height: ", ";\n\n  > svg {\n    max-width: 150rem;\n    max-height: 150rem;\n  }\n"])), function (props) {
  return props.size === 'fullscreen' ? 'fixed' : 'initial';
}, function (props) {
  return props.size === 'fullscreen' ? 0 : 'auto';
}, function (props) {
  return props.size === 'fullscreen' ? 0 : 'auto';
}, function (props) {
  return props.size === 'fullscreen' ? '100%' : '22rem';
}, function (props) {
  return props.size === 'fullscreen' ? '100%' : '22rem';
});
var rotator = keyframes(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(270deg); }\n"])));
var colors = keyframes(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  0% { stroke: ", "; }\n  25% { stroke: ", "; }\n  50% { stroke: ", "; }\n  75% { stroke: ", "; }\n  100% { stroke: ", "; }\n"])), colorPalette.blue, colorPalette.green, colorPalette.orange, colorPalette.red, colorPalette.beige);
var dash = keyframes(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  0% { stroke-dashoffset: ", "; }\n  50% {\n    stroke-dashoffset: ", ";\n    transform:rotate(135deg);\n  }\n  100% {\n    stroke-dashoffset: ", ";\n    transform:rotate(450deg);\n  }\n"])), OFFSET, OFFSET / 4, OFFSET);
var SpinnerContainer = styled.svg(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  animation: ", " ", "s linear infinite;\n"])), rotator, DURATION);
var Circle = styled.circle(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  stroke-dasharray: ", ";\n  stroke-dashoffset: 0;\n  transform-origin: center;\n  stroke: ", ";\n  animation: ", ";\n"])), OFFSET, function (props) {
  return props.color ? props.color : 'white';
}, function (props) {
  return props.monochrome ? css(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n          ", " ", "s ease-in-out infinite\n        "])), dash, DURATION) : css(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n          ", " ", "s ease-in-out infinite, ", " ", "s ease-in-out infinite\n        "])), dash, DURATION, colors, DURATION * 4);
});