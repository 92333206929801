import { css } from 'styled-components'

// POSITIONS
export const positions = {
  absolute: {
    topLeft: css`
      position: absolute;
      left: 0;
      top: 0;
    `,
  },
}

export default positions
