import { css } from 'styled-components'
import { RGBAColors } from './colors'

const shadows = {
  default: css`
    box-shadow: 0 3rem 8rem ${RGBAColors.shadow};
  `,
  defaultInset: css`
    box-shadow: inset 7rem 0 8rem -8rem ${RGBAColors.shadow};
  `,
  insetDarker: css`
    box-shadow: inset 7rem 0 8rem -8rem ${RGBAColors.shadowDark};
  `,
  lg: css`
    box-shadow: 0 6rem 16rem ${RGBAColors.shadowDark};
  `,
}

export default shadows
