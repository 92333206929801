import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2;
var __jsx = React.createElement;
import * as React from 'react';
import styled from 'styled-components';
import { fonts, colorPalette } from '../../../styles/styleGuide';
import { fromBreakpoint } from '../../../styles/breakpoints';
var Quote = function Quote(_ref) {
  var children = _ref.children;
  return __jsx(QuoteText, {
    "data-id": "richText_Quote_QuoteText"
  }, children);
};
export default Quote;
var QuoteText = styled.blockquote(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 20rem;\n  font-style: italic;\n  line-height: 34rem;\n  box-sizing: border-box;\n  padding-left: 5%;\n  margin: 20rem 0;\n  color: ", ";\n\n  ", ";\n"])), fonts.primary, colorPalette.beige, fromBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    font-size: 24rem;\n    line-height: 40rem;\n    padding-left: 10%;\n    margin: 40rem 0;\n  "]))));