import { CoveredImageProps } from '@/atoms/CoveredImage.props'
import { ImageSliderProps } from '@/atoms/ImageSlider.props'
import { FloorPlanProps } from '@/components/houseDesign/FloorPlan.props'
import { Phase } from '@/entities/types'
import { WishList } from '@/entities/wishList'

export type HouseDesignModalState =
  | {
      type: 'FloorPlan'
      props: FloorPlanProps
    }
  | {
      type: 'ImageSlider'
      props: ImageSliderProps
    }
  | {
      type: 'Image'
      props: CoveredImageProps
    }

type WishListState = {
  data?: WishList
  sectionLoadingStates: Record<string, boolean>
  layoutImages?: Record<string, string | undefined>
}

export type HouseDesignState = {
  wishLists: {
    [id: string]: WishListState
  }
  modal?: HouseDesignModalState
  menuOpen: boolean
  phase?: Phase
}

enum ActionType {
  addData = 'WISH_LIST_ADD_DATA',
  setLayoutImage = 'WISH_LIST_SET_LAYOUT_IMAGE',
  openModal = 'WISH_LIST_OPEN_MODAL',
  closeModal = 'WISH_LIST_CLOSE_MODAL',
  toggleMenu = 'WISH_LIST_TOGGLE_MENU',
  toggleSectionLoading = 'WISH_LIST_TOGGLE_SECTION_LOADING',
  setPhase = 'WISH_LIST_SET_PHASE',
}

type AddDataAction = {
  type: ActionType.addData
  payload: {
    id: string
    data: WishList
    layoutImages?: Record<string, string | undefined>
  }
}

type SetLayoutImageAction = {
  type: ActionType.setLayoutImage
  payload: {
    id: string
    slug: string
    url?: string
  }
}

type OpenModalAction = {
  type: ActionType.openModal
  payload: HouseDesignModalState
}

type CloseModalAction = {
  type: ActionType.closeModal
}

type ToggleMenuAction = {
  type: ActionType.toggleMenu
  payload: boolean
}

type ToggleSectionLoadingAction = {
  type: ActionType.toggleSectionLoading
  payload: {
    id: string
    slug: string
    loading: boolean
  }
}

type SetPhaseAction = {
  type: ActionType.setPhase
  payload: Phase
}

type Action =
  | AddDataAction
  | SetLayoutImageAction
  | OpenModalAction
  | CloseModalAction
  | ToggleMenuAction
  | ToggleSectionLoadingAction
  | SetPhaseAction

const defaultState: HouseDesignState = {
  wishLists: {},
  modal: undefined,
  menuOpen: false,
}

// eslint-disable-next-line default-param-last
const reducer = (state: HouseDesignState = defaultState, action: Action): HouseDesignState => {
  switch (action.type) {
    case ActionType.addData: {
      const { id, data, layoutImages } = action.payload
      return {
        ...state,
        wishLists: {
          ...state.wishLists,
          [id]: {
            data,
            sectionLoadingStates: state.wishLists[id]?.sectionLoadingStates ?? {},
            layoutImages: state.wishLists[id]?.layoutImages ?? layoutImages,
          },
        },
      }
    }

    case ActionType.setLayoutImage: {
      const { id, slug, url } = action.payload
      return {
        ...state,
        wishLists: {
          ...state.wishLists,
          [id]: {
            ...state.wishLists[id],
            layoutImages: {
              ...(state.wishLists[id]?.layoutImages ?? {}),
              [slug]: url,
            },
          },
        },
      }
    }

    case ActionType.openModal:
      return {
        ...state,
        modal: action.payload,
      }

    case ActionType.closeModal:
      return {
        ...state,
        modal: undefined,
      }

    case ActionType.toggleMenu:
      return {
        ...state,
        menuOpen: action.payload,
      }

    case ActionType.toggleSectionLoading: {
      const { id, slug, loading } = action.payload
      return {
        ...state,
        wishLists: {
          ...state.wishLists,
          [id]: {
            ...state.wishLists[id],
            sectionLoadingStates: {
              ...state.wishLists[id].sectionLoadingStates,
              [slug]: loading,
            },
          },
        },
      }
    }

    case ActionType.setPhase: {
      return {
        ...state,
        phase: action.payload,
      }
    }

    default:
      return state
  }
}

export const addWishListData = (
  id: string,
  data: WishList,
  layoutImages?: Record<string, string | undefined>
): AddDataAction => ({
  type: ActionType.addData,
  payload: {
    id,
    data,
    layoutImages,
  },
})

export const setLayoutImage = (id: string, slug: string, url?: string): SetLayoutImageAction => ({
  type: ActionType.setLayoutImage,
  payload: {
    id,
    slug,
    url,
  },
})

export const openModal = (payload: HouseDesignModalState): OpenModalAction => ({
  type: ActionType.openModal,
  payload,
})

export const closeModal = (): CloseModalAction => ({
  type: ActionType.closeModal,
})

export const toggleMenu = (payload: boolean): ToggleMenuAction => ({
  type: ActionType.toggleMenu,
  payload,
})

export const toggleSectionLoading = (
  wishListId: string,
  slug: string,
  loading: boolean
): ToggleSectionLoadingAction => ({
  type: ActionType.toggleSectionLoading,
  payload: {
    id: wishListId,
    slug,
    loading,
  },
})

export const setPhase = (phase: Phase): SetPhaseAction => ({
  type: ActionType.setPhase,
  payload: phase,
})

export default reducer
