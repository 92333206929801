import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { colorPalette } from '../../../styles/styleGuide';
import GoToCaret from '../icons/GoToCaret';
var defaultProps = {
  color: colorPalette.blue,
  caretColor: '#ffffff',
  size: 'normal'
};
var CaretContainer = function CaretContainer(_ref) {
  var size = _ref.size,
    color = _ref.color;
  return __jsx(Container, {
    size: size,
    "data-id": "designElements_CaretContainer_Container"
  }, __jsx(GoToCaret, {
    color: color,
    "data-id": "designElements_CaretContainer_GoToCaret"
  }));
};
CaretContainer.defaultProps = defaultProps;
var Container = styled.span(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  margin-top: 3rem;\n\n  ", ";\n"])), function (props) {
  return props.size === 'normal' ? "\n    margin-left: 10rem;\n  " : "\n    margin-left: 8rem;\n    svg {\n      transform: scale(.8);\n    }\n  ";
});
export default CaretContainer;