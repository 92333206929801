import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
// state def
var defaultState = {
  mobileMenuIsOpen: false
};

// actions

// reducer

export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case 'UPDATE_MOBILE_MENU_STATE':
      return _objectSpread(_objectSpread({}, state), action.payload);
    default:
      return state;
  }
});

// action creators

export var updateMobileMenuState = function updateMobileMenuState(isOpen) {
  return {
    type: 'UPDATE_MOBILE_MENU_STATE',
    payload: {
      mobileMenuIsOpen: isOpen
    }
  };
};

// selectors and MenuItem functions

export var hasSubmenu = function hasSubmenu(menuItem) {
  return !!menuItem.pages && menuItem.pages.length > 1;
};

// Does the current route have a submenu
export var isSubmenuActive = function isSubmenuActive(match, menuState) {
  return menuState.some(function (menuItem) {
    return menuItem.slug === match.params.menuSlug && hasSubmenu(menuItem);
  });
};