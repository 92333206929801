/* eslint-disable no-console */

const devDo =
  (f: (...args: unknown[]) => void) =>
  (...args: unknown[]) => {
    if (process.env.NODE_ENV === 'development' || process.env.NEXT_PUBLIC_DEBUG === 'true') {
      f(...args)
    }
  }

export const devError = devDo(console.error)
export const devLog = devDo(console.log)
export const devWarn = devDo(console.warn)
