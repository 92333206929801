import { css } from 'styled-components'

export const FONT_SIZE_BASE = 8

// FONTS
export const fonts = {
  primary: 'Foco, sans-serif',
}

export const fontWeight = {
  medium: 400,
  bold: 700,
}

export const fontStyles = {
  title: {
    lg: css`
      font-family: ${fonts.primary};
      font-weight: ${fontWeight.bold};
      font-size: ${FONT_SIZE_BASE * 4}rem;
      line-height: 1.25;
    `,
    md: css`
      font-family: ${fonts.primary};
      font-weight: ${fontWeight.bold};
      font-size: ${FONT_SIZE_BASE * 3}rem;
      line-height: 1.25;
    `,
    sm: css`
      font-family: ${fonts.primary};
      font-weight: ${fontWeight.bold};
      font-size: ${FONT_SIZE_BASE * 2.5}rem;
      line-height: 1.25;
    `,
    xs: css`
      font-family: ${fonts.primary};
      font-weight: ${fontWeight.bold};
      font-size: ${FONT_SIZE_BASE * 2}rem;
    `,
  },
  body: {
    lg: css`
      font-family: ${fonts.primary};
      font-size: ${FONT_SIZE_BASE * 2.25}rem;
    `,
    md: css`
      font-family: ${fonts.primary};
      font-size: ${FONT_SIZE_BASE * 2}rem;
    `,
    sm: css`
      font-family: ${fonts.primary};
      font-size: ${FONT_SIZE_BASE * 1.75}rem;
    `,
  },
  button: {
    md: css`
      font-family: ${fonts.primary};
      font-weight: ${fontWeight.bold};
      font-size: ${FONT_SIZE_BASE * 2}rem;
    `,
    sm: css`
      font-family: ${fonts.primary};
      font-weight: ${fontWeight.bold};
      font-size: ${FONT_SIZE_BASE * 1.25}rem;
    `,
  },
}
