import ResizeObserver from 'resize-observer-polyfill';
var observers = new Map();

// Observe the size of an element. The observer function gets called with the ContentRect immediately and whenever the
// size of the element changes, using ResizeObserver. Supports only one observer per element.
export var observeElementSize = function observeElementSize(element, observerFn) {
  var observer = new ResizeObserver(function (entries) {
    if (entries.length === 1) {
      observerFn(entries[0].contentRect);
    }
  });
  observers.set(element, observer);
  observer.observe(element);
};

// Remove size observer from element.
export var unobserveElementSize = function unobserveElementSize(element) {
  var observer = observers.get(element);
  if (observer) {
    observer.disconnect();
    observers["delete"](element);
  }
};