import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject;
var __jsx = React.createElement;
import * as React from 'react';
import styled from 'styled-components';
import { textStyles, colorPalette } from '../../../styles/styleGuide';
var Heading2 = function Heading2(_ref) {
  var children = _ref.children;
  return __jsx(Heading, {
    "data-id": "richText_Heading2_Heading"
  }, children);
};
export default Heading2;
var Heading = styled.h2(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n  color: ", ";\n  margin-top: 0;\n  margin-bottom: 25rem;\n"])), textStyles.sectionTitle, colorPalette.headerGrey);