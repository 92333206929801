import { css } from 'styled-components'
import isElectron from 'is-electron'

export enum BreakpointSize {
  xxl = 'xxl',
  xl = 'xl',
  slg = 'slg',
  lg = 'lg',
  md = 'md',
  sm = 'sm',
}

export const breakpointSizes: Record<BreakpointSize, number> = {
  xxl: 1920,
  xl: 1439,
  slg: 1220,
  lg: isElectron() ? 1160 : 1067,
  md: isElectron() ? 1160 : 689,
  sm: 320,
}

export const breakpointSizesInEm: Record<BreakpointSize, number> = {
  xxl: 120,
  xl: 89.9375,
  slg: 76.25,
  lg: isElectron() ? 72.5 : 66.6875,
  md: isElectron() ? 72.5 : 43.0625,
  sm: 20,
}

type MQFunctionObject = Record<BreakpointSize, typeof css>

export const toBreakpoint = (
  Object.entries(breakpointSizesInEm) as [BreakpointSize, number][]
).reduce((accumulator, [key, value]) => {
  // @ts-ignore
  accumulator[key] = (...args) => css`
    @media (max-width: ${value}em) {
      ${
        // @ts-ignore
        css(...args)
      };
    }
  `
  return accumulator
}, {} as MQFunctionObject)

export const fromBreakpoint = (
  Object.entries(breakpointSizesInEm) as [BreakpointSize, number][]
).reduce((accumulator, [key, value]) => {
  // @ts-ignore
  accumulator[key] = (...args) => css`
    @media (min-width: ${value}em) {
      ${
        // @ts-ignore
        css(...args)
      };
    }
  `
  return accumulator
}, {} as MQFunctionObject)
