/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */

// Fix Next Link to accept a className, for use with styled-components (https://github.com/vercel/next.js/issues/1942)

import React, { CSSProperties, HTMLAttributeAnchorTarget, ReactNode } from 'react'
import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import isElectron from 'is-electron'

export type LinkProps = Omit<NextLinkProps, 'href' | 'passHref'> & {
  className?: string
  style?: CSSProperties
  target?: HTMLAttributeAnchorTarget
  rel?: string
  children?: ReactNode
  href: string
}

const Link = ({ className, style, children, onClick, target, rel, href, ...rest }: LinkProps) => {
  // the electron browser has trouble handling Next.js link components
  if (isElectron()) {
    return (
      <a
        className={className}
        onClick={onClick}
        style={style}
        target={target}
        rel={rel}
        href={href}
      >
        {children}
      </a>
    )
  }

  return (
    <NextLink
      {...rest}
      href={href}
      prefetch={false}
      passHref
    >
      <a
        className={className}
        onClick={onClick}
        style={style}
        target={target}
        rel={rel}
      >
        {children}
      </a>
    </NextLink>
  )
}
export default Link
