import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2;
var __jsx = React.createElement;
import React, { useMemo } from 'react';
import styled from 'styled-components';
import ImageLoader from './shared/ui/ImageLoader';
import UserIcon from './shared/icons/Login';
import PlaceholderUser from './shared/icons/PlaceholderUser';
import PlaceholderUserCJ from './shared/icons/PlaceholderUserCJ';
import { colorPalette } from '../styles/styleGuide';
import formatCmsImageUrl from '@/services/formatCmsImageUrl';
var sizes = {
  xsmall: 24,
  small: 36,
  normal: 48,
  normaler: 70,
  larger: 100,
  large: 112,
  xlarge: 180
};
var placeholderSizes = {
  xsmall: 15,
  small: 20,
  normal: 30,
  normaler: 42,
  larger: 55,
  large: 60,
  xlarge: 80
};
var borderWidths = {
  xsmall: 0,
  small: 1,
  normal: 2,
  normaler: 3,
  larger: 2,
  large: 2,
  xlarge: 4
};
var UserAvatar = function UserAvatar(_ref) {
  var size = _ref.size,
    imageUrl = _ref.imageUrl,
    name = _ref.name,
    phase = _ref.phase,
    mode = _ref.mode;
  var src = useMemo(function () {
    return formatCmsImageUrl(60, 60, imageUrl);
  }, [imageUrl]);
  return __jsx(UserImage, {
    size: sizes[size],
    borderWidth: borderWidths[size],
    "data-id": "components_UserAvatar_UserImage"
  }, src && mode === 'bg' && __jsx(BackgroundImage, {
    image: src,
    "data-id": "components_UserAvatar_BackgroundImage"
  }), src && mode === 'imageLoader' && __jsx(ImageLoader, {
    src: src,
    position: "relative",
    mode: "bg",
    alt: name,
    width: sizes[size],
    height: sizes[size],
    rectangular: true,
    "data-id": "components_UserAvatar_ImageLoader"
  }), !src && !phase && __jsx(UserIcon, {
    color: colorPalette.lightOrange,
    width: placeholderSizes[size],
    height: placeholderSizes[size],
    "data-id": "components_UserAvatar_UserIcon"
  }), !src && phase === 'cj1' && __jsx(PlaceholderUser, {
    color: colorPalette.lightOrange,
    width: placeholderSizes[size],
    height: placeholderSizes[size],
    paddingBottom: placeholderSizes[size],
    "data-id": "components_UserAvatar_PlaceholderUser"
  }), !src && (phase === 'cj2' || phase === 'cj3') && __jsx(PlaceholderUserCJ, {
    color: colorPalette.lightOrange,
    width: placeholderSizes[size],
    height: placeholderSizes[size],
    "data-id": "components_UserAvatar_PlaceholderUserCJ"
  }));
};
export default UserAvatar;
var UserImage = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-shrink: 0;\n  align-self: auto;\n  justify-content: center;\n  align-content: center;\n  background-color: ", ";\n  width: ", "rem;\n  height: ", "rem;\n  border-radius: 50%;\n  overflow: hidden;\n  border: ", "rem solid white;\n\n  > div {\n    border-radius: 50%;\n    width: ", "rem;\n    height: ", "rem;\n  }\n\n  > svg {\n    align-self: center;\n    margin-left: 1rem;\n    margin-bottom: 1rem;\n    padding-bottom: ", "rem;\n  }\n"])), colorPalette.loadingOrange, function (props) {
  return props.size;
}, function (props) {
  return props.size;
}, function (props) {
  return props.borderWidth;
}, function (props) {
  return props.size;
}, function (props) {
  return props.size;
}, function (props) {
  return props.size > 70 ? props.size / 15 : 0;
});
var BackgroundImage = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  background-image: url(", ");\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: cover;\n"])), function (props) {
  return props.image;
});