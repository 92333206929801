var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.white,
  width: 16,
  height: 16
};
var CloseCross = function CloseCross(_ref) {
  var width = _ref.width,
    height = _ref.height,
    color = _ref.color;
  return __jsx("svg", {
    style: {
      width: "".concat(String(width), "px"),
      height: "".concat(String(height), "px")
    },
    viewBox: "0 0 16 16",
    "data-id": "icons_CloseCross_svg"
  }, __jsx("title", {
    "data-id": "icons_CloseCross_title"
  }, "Sluit icoon"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_CloseCross_g"
  }, __jsx("g", {
    transform: "translate(-1287.000000, -75.000000)",
    fill: color,
    "data-id": "icons_CloseCross_g_3"
  }, __jsx("g", {
    transform: "translate(1212.000000, 63.000000)",
    "data-id": "icons_CloseCross_g_4"
  }, __jsx("g", {
    transform: "translate(63.000000, 0.000000)",
    "data-id": "icons_CloseCross_g_5"
  }, __jsx("path", {
    d: "M23.5915032,20 L27.6306154,15.9600063 C28.1231282,15.4661165 28.1231282,14.6586256 27.6306154,14.1647358 L25.8331978,12.3694652 C25.3394157,11.8768449 24.5308316,11.8768449 24.0383189,12.3694652 L19.9992066,16.4094588 L15.9600944,12.3694652 C15.4675817,11.8768449 14.6589976,11.8768449 14.1652155,12.3694652 L12.3703366,14.1647358 C11.8765545,14.6586256 11.8765545,15.4661165 12.3703366,15.9600063 L16.4094488,20 L12.3703366,24.0412633 C11.8765545,24.5338835 11.8765545,25.342644 12.3703366,25.8365339 L14.1652155,27.6305348 C14.6589976,28.1231551 15.4675817,28.1231551 15.9600944,27.6305348 L19.9992066,23.5918108 L24.0383189,27.6305348 C24.5308316,28.1231551 25.3394157,28.1231551 25.8357365,27.6305348 L27.6306154,25.8365339 C28.1231282,25.342644 28.1231282,24.5338835 27.6306154,24.0412633 L23.5915032,20 Z",
    "data-id": "icons_CloseCross_path"
  }))))));
};
CloseCross.defaultProps = defaultProps;
export default CloseCross;