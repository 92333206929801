import { ReactNode } from 'react'
import styled from 'styled-components'
import { SIZE_MAP, Sizes } from '@/styles/spacing'

type CardinalProps = {
  marginTop?: Sizes
  marginRight?: Sizes
  marginBottom?: Sizes
  marginLeft?: Sizes
  paddingTop?: Sizes
  paddingRight?: Sizes
  paddingBottom?: Sizes
  paddingLeft?: Sizes
}

export type BoxProps = {
  children?: ReactNode
  margin?: Sizes
  marginHorizontal?: Sizes
  marginVertical?: Sizes
  padding?: Sizes
  paddingHorizontal?: Sizes
  paddingVertical?: Sizes
} & CardinalProps

const Container = styled.div<CardinalProps>`
  ${({ marginTop }) => marginTop && `margin-top: ${SIZE_MAP[marginTop]}rem`};
  ${({ marginRight }) => marginRight && `margin-right: ${SIZE_MAP[marginRight]}rem`};
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${SIZE_MAP[marginBottom]}rem`};
  ${({ marginLeft }) => marginLeft && `margin-left: ${SIZE_MAP[marginLeft]}rem`};
  ${({ paddingTop }) => paddingTop && `padding-top: ${SIZE_MAP[paddingTop]}rem`};
  ${({ paddingRight }) => paddingRight && `padding-right: ${SIZE_MAP[paddingRight]}rem`};
  ${({ paddingBottom }) => paddingBottom && `padding-bottom: ${SIZE_MAP[paddingBottom]}rem`};
  ${({ paddingLeft }) => paddingLeft && `padding-left: ${SIZE_MAP[paddingLeft]}rem`};
`

const getContainerProps = ({
  margin,
  marginHorizontal = margin,
  marginVertical = margin,
  padding,
  paddingHorizontal = padding,
  paddingVertical = padding,
  ...rest
}: Omit<BoxProps, 'children'>) => ({
  marginTop: marginVertical,
  marginRight: marginHorizontal,
  marginBottom: marginVertical,
  marginLeft: marginHorizontal,
  paddingTop: paddingVertical,
  paddingRight: paddingHorizontal,
  paddingBottom: paddingVertical,
  paddingLeft: paddingHorizontal,
  ...rest,
})

const Box = ({ children, ...props }: BoxProps) => (
  <Container {...getContainerProps(props)}>{children}</Container>
)

export default Box
