export const colors = {
  black: '#000000',
  brand: '#EC6608',
  lightOrange: '#EF873E',
  gray05: '#FCF9F8',
  gray10: '#F4F1EF',
  gray15: '#ECE9E7',
  gray20: '#C8C5BF',
  gray25: '#aeaba8',
  gray30: '#8D8379',
  gray40: '#534D47',
  white: '#FFFFFF',
  blue: '#1FABDE',
  green: '#72C045',
  red: '#D0021B',
}

export const RGBAColors = {
  shadow: `rgba(0, 0, 0, 0.2)`,
  shadowDark: `rgba(0, 0, 0, 0.4)`,
  white12: `rgba(255, 255, 255, 0.12)`,
  gray12: `rgba(141, 131, 121, 0.12)`,
  white50: `rgba(255, 255, 255, 0.5)`,
  black70: `rgba(0, 0, 0, 0.7)`,
  white10: `rgba(255,255,255,0.1)`,
}
