var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.orange,
  width: 15,
  height: 15
};
var PlaceholderUser = function PlaceholderUser(_ref) {
  var width = _ref.width,
    height = _ref.height,
    color = _ref.color;
  return __jsx("svg", {
    style: {
      width: "".concat(String(width), "px"),
      height: "".concat(String(height), "px")
    },
    viewBox: "0 0 13 13",
    version: "1.1",
    "data-id": "icons_PlaceholderUser_svg"
  }, __jsx("title", {
    "data-id": "icons_PlaceholderUser_title"
  }, "PlaceholderUser"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_PlaceholderUser_g"
  }, __jsx("g", {
    transform: "translate(-1057.000000, -21.000000)",
    fillRule: "nonzero",
    fill: color,
    "data-id": "icons_PlaceholderUser_g_3"
  }, __jsx("g", {
    transform: "translate(1051.000000, 16.000000)",
    "data-id": "icons_PlaceholderUser_g_4"
  }, __jsx("g", {
    "data-id": "icons_PlaceholderUser_g_5"
  }, __jsx("g", {
    "data-id": "icons_PlaceholderUser_g_6"
  }, __jsx("path", {
    d: "M12.5,5 L19,8.9 L18.9796875,16.7609375 C18.9796875,17.43125 18.43125,17.9796875 17.7609375,17.9796875 L6,17.9796875 L6,8.8796875 L12.5,5 Z M12.5,6.178125 L7.015625,9.4484375 L7.0359375,16.94375 L17.3546875,16.9640625 C17.7,16.9640625 17.9640625,16.7 17.9640625,16.3546875 L17.9640625,9.46875 L12.5,6.178125 Z M12.1195439,12.5256075 C11.6720866,12.2840374 11.3625,11.763583 11.3625,11.1604911 C11.3625,10.3271328 11.953624,9.6515625 12.6828125,9.6515625 C13.412001,9.6515625 14.003125,10.3271328 14.003125,11.1604911 C14.003125,11.6862254 13.7678652,12.1491621 13.4110975,12.419299 C13.428059,12.467985 13.4372768,12.520396 13.4372768,12.5751116 C13.4372768,12.835536 13.2251457,13.0466518 12.9643429,13.0466518 L12.5898981,13.0466518 C12.328704,13.0466518 12.1169643,12.8373517 12.1169643,12.5751116 C12.1169643,12.5583927 12.1178386,12.5418771 12.1195439,12.5256075 Z M11.597981,12.6694197 C11.7371068,13.29493 12.1701507,13.7510603 12.6828125,13.7510603 C13.1954743,13.7510603 13.6285182,13.29493 13.767644,12.6694197 L14.1904769,12.6694197 C14.8161938,12.6694197 15.3234375,13.2519987 15.3234375,13.9673884 L15.3234375,15.2653572 L10.0421875,15.2653572 L10.0421875,13.9673884 C10.0421875,13.25054 10.548576,12.6694197 11.1751481,12.6694197 L11.597981,12.6694197 Z",
    "data-id": "icons_PlaceholderUser_path"
  })))))));
};
PlaceholderUser.defaultProps = defaultProps;
export default PlaceholderUser;